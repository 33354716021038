import { Pipe, PipeTransform } from '@angular/core';
import { getId } from '@cawita/core-front';

@Pipe({
  name: 'includeId'
})

export class IncludeIdPipe implements PipeTransform {
  transform(value: string, array:any):boolean {
    if(!value) return false;
    if(!array) return false;
    if(!array.map(getId).includes(getId(value))) return false;
    return true;
  }
}