import { CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { Customer } from "./customer.model";
import { LocalizedAddress } from "./localized-address.model";

@CwtModel({ name: 'customer-address' })
@CwtCrudContract({
    path: 'mine/customer-address',
    pathPrefix: 'as-customer',
    searchMapper: (search) => ({
        or: [
            { 'address.line1': { regexp: search } },
            { 'address.line2': { regexp: search } },
            { 'address.city': { regexp: search } },
            { 'address.zip': { regexp: search } },
            { 'address.country': { regexp: search } }
        ]
    })
})
export class CustomerAddress extends LocalizedAddress {
    @CwtProp({ ref: Customer }) customer: Customer;
    @CwtProp() name: string;
}