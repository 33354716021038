import { CwtApiEntity, CwtCrudContract, CwtProp, CwtSchema } from '@cawita/core-front/api';
import type { Customer } from './customer.model';
import type { Mission } from './mission.model';
import type { Pro } from './pro.model';

export enum RateStatus {
    Pending = 'pending',
    Validated = 'validated',
    Canceled = 'canceled'
}

@CwtSchema()
export abstract class BaseRating extends CwtApiEntity {
    @CwtProp({ ref: 'customer' }) customer: Customer;
    @CwtProp({ ref: 'pro' }) pro: Pro;
    @CwtProp({ ref: 'mission' }) mission: Mission;
    @CwtProp() comment?: string;
    @CwtProp() status: RateStatus;
    @CwtProp() date: Date;

    abstract rate: number;
}
@CwtSchema()
export class BaseRatingMeta<T extends {}> {
    @CwtProp() nbRate: number;
    @CwtProp() nbMissionFinished: number;
    @CwtProp() average: RatingDetails<T>;
    @CwtProp() total: RatingDetails<T>;
}

export type RatingDetails<T> = { all: number; } & Record<keyof T, number>;

export function sumRate(...values: number[]): number {
    return values.reduce((total, v) => total + (v ?? 0)) / values.length;
}