import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { hasSomeChanges } from '@cawita/core-front';
import { AbstractMediaDirective } from '../abstract-media';

@Component({
  selector: 'cwt-video',
  templateUrl: './video.component.html',
  styleUrls: [
    '../media-core-styles.less',
    './video.component.less'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CwtVideoComponent extends AbstractMediaDirective implements OnInit, OnChanges, OnDestroy {

  @Input() src: string | File = null;
  @Input() poster: string = null;

  @Input() controls = false;

  public srcType: 'file' | 'url' = 'url';
  public displayedSrc: string;

  constructor(private cdRef: ChangeDetectorRef) { super() }

  ngOnInit(): void {
    this._recomputeSource(this.src);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasSomeChanges(changes, ['src'])) this._recomputeSource(this.src);
  }

  ngOnDestroy(): void {
    if (this.srcType === 'file') URL.revokeObjectURL(this.displayedSrc);
  }

  private _recomputeSource(source: string | File) {
    this.state = !!source ? 'loading' : 'placeholder';
    if (this.srcType === 'file') {
      URL.revokeObjectURL(this.displayedSrc);
      this.displayedSrc = null;
    }

    if (!(source instanceof Blob)) {
      this.srcType = 'url';
      this.displayedSrc = source ?? null;
    } else {
      this.srcType = 'file';
      this._readFile(source).then(src => {
        this.displayedSrc = src;
        this.cdRef.markForCheck();
      });
    }
  }

  public onMediaLoaded(): void {
    this.state = 'source';
    this.cdRef.markForCheck();
  }

  public onMediaError(): void {
    this.state = 'error';
    this.cdRef.markForCheck();
  }


  private _readFile(file: File) {
    return new Promise<string>((res, rej) => {
      var reader = new FileReader();
      reader.onload = function (e) {
        var contents = e.target.result as ArrayBuffer;
        var uint8Array = new Uint8Array(contents);

        var arrayBuffer = uint8Array.buffer;
        var blob = new Blob([arrayBuffer]);
        res(URL.createObjectURL(blob));

      }
      reader.readAsArrayBuffer(file);
    });
  }
}
