import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cwt-currency',
  template: `{{((amount || 0) / 100) | cwtLocaleCurrency: (currency | uppercase)}}`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CurrencyComponent implements OnInit {

  @Input() amount: number;
  @Input() currency: string;

  constructor() { }

  ngOnInit(): void {
  }

}
