import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { CustomerRateProps } from "./customer-rate.model";
import { BaseRatingMeta } from "./rating.model";
import { UploadFile } from "./upload-file.model";
import { NotificationConfig, User } from "./user.model";
import { forwardRef } from "@angular/core";

@CwtModel({ name: 'customer' })
@CwtCrudContract({
    path: 'customer',
    searchMapper: (s) => ({
        or: [
            { firstName: { regexp: s } },
            { lastName: { regexp: s } },
            { email: { regexp: s } },
        ]
    })
})
@CwtCrudContract({ pathPrefix: 'as-customer', path: 'customer' })
@CwtCrudContract({ pathPrefix: 'as-pro', path: 'mine/customer' })
export class Customer extends CwtApiEntity {
    @CwtProp({ ref: User }) user: User;
    @CwtProp({ ref: BaseRatingMeta }) rate: BaseRatingMeta<typeof CustomerRateProps>;
    @CwtProp({ ref: UploadFile }) picture: UploadFile;

    @CwtProp() firstName: string;
    @CwtProp() lastName: string;
    @CwtProp() email: string;
    @CwtProp() phone: string;
    @CwtProp() lang: string;
    @CwtProp() missionCount: number;
    @CwtProp() firstMissionDate: Date;

    @CwtProp() isProCustomer: boolean;
    @CwtProp({ ref: forwardRef(() => NotificationConfig) }) notification: NotificationConfig;
}