import { CommonModule } from '@angular/common';
import { forwardRef, ModuleWithProviders, NgModule } from '@angular/core';
import { AppCommonModule } from '@shared/modules';
import { CwtMediaModule } from '@shared/modules/media/media.module';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { MEDIA_VIEWER_ADAPTER } from '@shared/modules/media/media-viewer-adapter';
import { MediaViewerComponent } from './media-viewer.component';
import { MediaViewerService } from './media-viewer.service';



@NgModule({
  declarations: [
    MediaViewerComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    CwtMediaModule,
    NzModalModule,
  ],
  providers: [
    MediaViewerService
  ]
})
export class MediaViewerModule {
  static forRoot(): ModuleWithProviders<MediaViewerModule> {
    return {
      ngModule: MediaViewerModule,
      providers: [
        { provide: MEDIA_VIEWER_ADAPTER, useExisting: forwardRef(() => MediaViewerService) }
      ]
    };
  }
}