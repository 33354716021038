import ng_fr_FR from '@angular/common/locales/fr';
import { ICwtCoreConfig } from '@cawita/core-front';
import { CwtDefaultAuthConfig } from '@cawita/core-front/auth';
import * as models from '@shared/models';
import { fr_FR } from 'ng-zorro-antd/i18n';
import { environment } from '../../environments/environment';

export const CwtAdminCoreConfig: ICwtCoreConfig = {
    api: {
        apiUrl: environment.apiUrl,
        models: Object.values(models) as any[],
    },
    asset: {
        assetUrl: environment.assetUrl,
        protectedAssetPath: environment.protectedAssetPath,
        publicAssetPath: environment.publicAssetPath,
    },
    auth: {
        stateStorageKey: 'cwt-kaarlo-admin-auth',
        api: {
            ...CwtDefaultAuthConfig?.api,
            registerEndpoint: 'user/subscribe'
        },
        navigation: {
            defaultRoute: ['/'],
            permissionDeniedRoute: ['/'],
            loginRoute: ['/auth', 'login'],
        },
    },
    i18n: {
        currentLocaleStorageKey: 'cwt-admin-locale',
        defaultCurrencyCode: 'EUR',
        defaultLocale: 'fr',
        locales: [
            { localeId: 'fr', localeName: 'Français', ngLocaleData: ng_fr_FR, extra: { nzLocaleData: fr_FR } },
            // { localeId: 'en', localeName: 'English', ngLocaleData: ng_en_US, extra: { nzLocaleData: en_US } },
        ],
        resources: [
            { prefix: '/assets/i18n/', suffix: '.json' },
            { prefix: '/assets/i18n/', suffix: '.errors.json', key: 'errors' },
            { prefix: '/assets/i18n/', suffix: '.countries.json', key: 'countries' },
        ]
    }
}