import { Injectable } from '@angular/core';
import { sameId } from '@cawita/core-front';
import { CwtApiFilter, CwtApiPageResponse, CwtApiService, CwtRequestConfig, mapArrayToPaginated } from '@cawita/core-front/api';
import { Intervention, Mission } from '@shared/models';
import { catchError, map, Observable, of, switchMap, tap } from 'rxjs';
import { AsProMissionApi } from './as-pro-mission.api';

export type MarketType = 'around' | 'targetted';

@Injectable({ providedIn: 'root' })
export class AsProInterventionApi {
    private basePath = 'as-pro/mine/intervention';
    constructor(
        private api: CwtApiService,
        private asProMissionApi: AsProMissionApi,
    ) { }

    public getInterventionForMarketType(type: MarketType, filters?: CwtApiFilter, config?: CwtRequestConfig) {
        if (type === 'targetted') return this.getInterventionsTargetted(filters, config);
        return this.getInterventionsAround(filters, config);
    }

    public getInterventionForMarketTypeWithMissions(type: MarketType, filters?: CwtApiFilter, config?: CwtRequestConfig) {
        return this.getInterventionForMarketType(type, filters, config).pipe(
            switchMap(res => this.asProMissionApi.getMissionsForInterventions(res.data).pipe(
                catchError(() => of([])),
                tap(missions => res.data.forEach(i => i.mission = missions.find(m => sameId(m.intervention, i)))),
                map(() => res)
            ))
        );
    }

    public getInterventionsAround(filters?: CwtApiFilter, config?: CwtRequestConfig): Observable<CwtApiPageResponse<Intervention>> {
        return this.api.get(`${this.basePath}/around`, filters, config).pipe(
            mapArrayToPaginated(),
            tap(res => res.data = res.data.map(intervention => new Intervention(intervention)))
        );
    }

    public getInterventionsTargetted(filters?: CwtApiFilter, config?: CwtRequestConfig): Observable<CwtApiPageResponse<Intervention>> {
        return this.api.get(`${this.basePath}/targetted`, filters, config).pipe(
            mapArrayToPaginated(),
            tap(res => res.data = res.data.map(intervention => new Intervention(intervention)))
        );
    }

    public getMissionForIntervention(interventionId: string): Observable<Mission> {
        return this.api.get(`${this.basePath}/${interventionId}/mission`).pipe(
            map(res => res ? new Mission(res) : null)
        );
    }
}