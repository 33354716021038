import { forwardRef } from "@angular/core";
import { CwtDictionary, CwtDictionaryObject } from "@cawita/core-front";
import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { Job } from "./job.model";
import { UploadFile } from "./upload-file.model";

@CwtModel({ name: 'category' })
@CwtCrudContract({
    path: 'category',
    searchMapper: (s) => ({
        or: [
            { 'name.fr': { regexp: s } },
        ]
    })
})
@CwtCrudContract({ path: 'category', pathPrefix: 'as-customer' })

export class Category extends CwtApiEntity {
    @CwtProp() name: CwtDictionaryObject;
    @CwtProp({ ref: UploadFile }) picture: UploadFile;
    @CwtProp() isActive: boolean;
    @CwtProp({ ref: 'job' }) jobs: Job[];
}