import { Directive, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { CwtDirectiveView, getId, sameId } from '@cawita/core-front';
import { Intervention, Mission } from '@shared/models';
import { AsCustomerMissionApi } from '@shared/services/apis/as-customer-mission.api';
import { Subscription } from 'rxjs';

export type SetInterventionTargettedProsMissionsContext = {
  $implicit: Intervention;
}

@Directive({ selector: '[cwtSetInterventionTargettedProsMissions]' })
export class SetInterventionTargettedProsMissions implements OnChanges, OnDestroy {

  private _ctx: SetInterventionTargettedProsMissionsContext = { $implicit: null };
  private _view = new CwtDirectiveView(this.vcRef, this.tRef, this._ctx);
  private _sub: Subscription;

  @Input('cwtSetInterventionTargettedProsMissions') intervention: Intervention;

  constructor(
    private vcRef: ViewContainerRef,
    private tRef: TemplateRef<SetInterventionTargettedProsMissionsContext>,
    private asCustomerMissionApi: AsCustomerMissionApi
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    const { intervention } = changes;
    if (!!intervention?.currentValue) this.refreshState(intervention?.currentValue);
  }

  refreshState(intervention: Intervention) {
    this._sub?.unsubscribe();
    this._sub = this.asCustomerMissionApi.getMissionForIntervention(getId(intervention)).subscribe(missions => {
      const targettedPros = intervention?.targettedPros;
      for (const pro of targettedPros) {
        pro['mission'] = missions.find((mission: Mission) => sameId(pro, mission?.pro));
      }
      intervention.targettedPros = targettedPros;
      this._ctx.$implicit = intervention;
      this._view.ensureState(true);
    });
  }

  ngOnDestroy(): void {
    this._sub?.unsubscribe();
  }


}
