import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, HostBinding, Input, OnChanges, OnInit, QueryList, SimpleChanges } from '@angular/core';
import { fileToBase64, hasSomeChanges, isset } from '@cawita/core-front';
import { AbstractMediaDirective } from '../abstract-media';
import { CwtMediaAddonDirective } from '../media-addon.directive';
import { MediaBorderRadius, CwtMediaFitting, CwtMediaShape, CwtMediaState } from '../media.utils';

@Component({
  selector: 'cwt-image',
  templateUrl: './image.component.html',
  styleUrls: [
    '../media-core-styles.less',
    './image.component.less'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CwtImageComponent extends AbstractMediaDirective implements OnInit, OnChanges {
  public displayedSrc: string;

  constructor(private cdRef: ChangeDetectorRef) { super(); }

  ngOnInit(): void {
    this._recomputeSource(this.src);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasSomeChanges(changes, ['src'])) this._recomputeSource(this.src);
  }

  private _recomputeSource(source: string | File) {
    this.state = !!source ? 'loading' : 'placeholder';
    if (!(source instanceof Blob)) {
      if (!isset(source)) this.displayedSrc = null;
      else this.displayedSrc = source;
      this.cdRef.markForCheck();
    } else {
      fileToBase64(source).then(base64 => {
        this.displayedSrc = base64;
        this.cdRef.markForCheck();
      });
    }
  }

  public onMediaLoaded(): void {
    this.state = 'source';
    this.cdRef.markForCheck();
  }

  public onMediaError(): void {
    this.state = 'error';
    this.cdRef.markForCheck();
  }

}
