import { InjectionToken } from "@angular/core";
import { CwtMediaType } from "./media.utils";

export const MEDIA_VIEWER_ADAPTER = new InjectionToken<ICwtMediaViewerAdapter>('cwt-media-viewer-adapter');


export type CwtMediaViewerOptions = {
    type: CwtMediaType;
    src: string | File;
    name: string;
    onDelete: () => void;
}

export interface ICwtMediaViewerAdapter {
    viewMedia(options: CwtMediaViewerOptions): void | Promise<void>;
}