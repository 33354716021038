import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminAuthService } from './admin-auth-service.service';
import { SOCKET_URL } from '@shared/services/apis';
import { environment } from '../../../environments/environment';
import { UnreadNotificationDirective } from './unread-notification.directive';
import { CWT_AUTH_LISTENER } from '@cawita/core-front/auth';



@NgModule({
  declarations: [
    UnreadNotificationDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    UnreadNotificationDirective
  ]
})
export class NotificationModule {
  static forRoot(): ModuleWithProviders<NotificationModule> {
    return {
      ngModule: NotificationModule,
      providers: [
        { provide: SOCKET_URL, useValue: environment.socketUrl },
        { provide: CWT_AUTH_LISTENER, useExisting: AdminAuthService, multi: true },
      ]
    }
  }
}
