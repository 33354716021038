import { CwtDictionary } from "@cawita/core-front";
import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { Category } from "./category.model";
import { Skill } from "./skill.model";
import { UploadFile } from "./upload-file.model";

@CwtModel({ name: 'job' })
@CwtCrudContract({ path: 'job' })
@CwtCrudContract({ path: 'job', pathPrefix: 'as-customer' })
export class Job extends CwtApiEntity {
    @CwtProp() name: CwtDictionary;
    @CwtProp({ ref: Category }) category: Category;
    @CwtProp({ ref: UploadFile }) picture: UploadFile;
    @CwtProp() isActive: boolean;
    @CwtProp({ ref: 'skill' }) skills: Skill[];
}