import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IfMissionStateDirective, InterventionIsDirectDirective, InterventionIsEmergencyDirective } from './directives';
import { InterventionHasManyMissionsDirective } from './directives/intervention-has-many-missions.directive';
import { MainMissionPipe } from './main-mission.pipe';
import { SetInterventionTargettedProsMissions } from './set-intervention-targetted-pros-missions.directive';
import { WithMissionDirective } from './directives/with-mission.directive';
import { InterventionIsQuoteDirective } from './directives/intervention-is-quote.directive';



@NgModule({
  declarations: [
    InterventionIsEmergencyDirective,
    InterventionIsDirectDirective,
    InterventionIsQuoteDirective,
    SetInterventionTargettedProsMissions,
    InterventionHasManyMissionsDirective,
    IfMissionStateDirective,
    MainMissionPipe,
    WithMissionDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    InterventionIsEmergencyDirective,
    InterventionIsDirectDirective,
    InterventionIsQuoteDirective,
    InterventionHasManyMissionsDirective,
    SetInterventionTargettedProsMissions,
    IfMissionStateDirective,
    MainMissionPipe,
    WithMissionDirective
  ]
})
export class SharedInterventionModule { }
