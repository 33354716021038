import { Injectable } from '@angular/core';
import { CwtApiService, CwtRequestConfig } from '@cawita/core-front/api';
import { Observable, map } from 'rxjs';
import { Mission } from '../../models';

@Injectable({ providedIn: 'root' })
export class AsCustomerMissionApi {

    constructor(private api: CwtApiService) { }

    public getMissionForIntervention(interventionId: string): Observable<Mission[]> {
        return this.api.get<any[]>(`as-customer/mine/intervention/${interventionId}/mission`).pipe(
            map(missions => missions.map(m => new Mission(m)))
        );
    }
    public cancelMission(missionId) {
        return this.api.post(`as-customer/mine/mission/${missionId}/customer-status/cancel`).pipe(
            map(mission => new Mission(mission))
        );
    }
    public finishMission(missionId: string, body?: any) {
        return this.api.post(`as-customer/mine/mission/${missionId}/customer-status/finished`, body).pipe(
            map(mission => new Mission(mission))
        );
    }
    public refuseMission(missionId) {
        return this.api.post(`as-customer/mine/mission/${missionId}/customer-status/refuse`).pipe(
            map(mission => new Mission(mission))
        );
    }
    public acceptMission(missionId) {
        return this.api.post(`as-customer/mine/mission/${missionId}/customer-status/accept`).pipe(
            map(mission => new Mission(mission))
        );
    }
}