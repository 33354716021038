import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp, CwtSchema, CwtVirtual } from "@cawita/core-front/api";
import { Pro } from "../pro.model";
import { Product } from "./product.model";
import { StripeSubscription } from "./stripe.model";

export enum SubscriptionType {
    Default = 'default',
    System = 'system',
    Admin = 'admin',
}

export enum SubscriptionStatus {
    Active = 'active',
    Paused = 'paused',
    Expired = 'expired'
}

export enum SubscriptionSubType {
    Free = 'free',
    Stripe = 'stripe',
    Apple = 'apple',
    Google = 'google'
}

export enum SubscriptionEventType {
    Human = 'human',
    System = 'system'
}

export enum SubscriptionEventAction {
    New = 'new',
    Renew = 'renew',
    Upgrade = 'upgrade',
    Downgrade = 'downgrade',
    Cancel = 'cancel'
}

@CwtSchema()
export class SubscriptionEvent {
    @CwtProp({ ref: Date }) date: Date;
    @CwtProp() type: SubscriptionEventType;
    @CwtProp() action: SubscriptionEventAction;
    @CwtProp() isSubscribed: boolean;
    @CwtProp() freeSubscription: any;
    @CwtProp({ ref: 'stripe-subscription' }) stripeSubscription: StripeSubscription;
    @CwtProp({ ref: 'product' }) products: Product[];
}

@CwtModel({ name: 'subscription' })
@CwtCrudContract({ path: 'subscription', searchMapper: (s) => ({ 'pro.companyName': { regexp: s } }) })
@CwtCrudContract({ path: 'mine/subscription', pathPrefix: 'as-pro' })
export class Subscription extends CwtApiEntity {
    @CwtProp({ ref: Pro }) pro: Pro;
    @CwtProp() isCurrent: boolean;
    @CwtProp() type: SubscriptionType;
    @CwtProp() subType: SubscriptionSubType;
    @CwtProp() status: SubscriptionStatus;
    @CwtProp({ ref: Product }) products: Product[];
    @CwtProp({ ref: Date }) startDate: Date;
    @CwtProp({ ref: Date }) endDate: Date;
    @CwtProp({ ref: SubscriptionEvent }) event: SubscriptionEvent;

    @CwtVirtual({ get: m => m?.products?.[0] }) product: Product;
}