
import { Injectable } from '@angular/core';
import { CwtApiService } from '@cawita/core-front/api';

@Injectable({ providedIn: 'root' })
export class ShowroomApi {
    constructor(private api: CwtApiService) { }

    deleteMedia(id: string, fileId: string) {
        return this.api.delete(`/showroom/${id}/company-media/${fileId}`);
    }

}

