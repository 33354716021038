import { InjectionToken, Injector, Type, inject } from "@angular/core";
import { formatURL, getId } from "@cawita/core-front";
import { CwtApiPageResponse, CwtCreateContract, CwtJsonApiService, formatSort, mapPaginatedToArray } from "@cawita/core-front/api";
import { CwtAuthStore } from "@cawita/core-front/auth";
import { CwtStore } from "@cawita/core-front/state";
import { Observable, forkJoin, map, switchMap, tap } from "rxjs";
import { Socket, io } from 'socket.io-client';

export const SOCKET_URL = new InjectionToken('cwt-socket-url');

/**
 * Crud Contract Options
 */
export type SimpleNotificationContractOptions = {
    /**
     * Base path to resource
     */
    notifGroup: string;
    notifPath: string;
    notifCheckPath: string;
}

export const SimpleNotificationContract = CwtCreateContract('cwt-simple-notification', {
    getParams: (notifGroup?: string) => [notifGroup],
    optionsToParams: (options: SimpleNotificationContractOptions) => [options?.notifGroup],
    provider: (model: Type<unknown>, options: SimpleNotificationContractOptions) => ({
        deps: [SOCKET_URL, CwtJsonApiService],
        useFactory: (url: string, api: CwtJsonApiService) => new SimpleNotificationService<unknown>(url, api, model, options),
    })
});

export type SimpleNotificationState<Model> = {
    items: Model[];
    unread: number;
    total: number;
    lastCheck: Date;
}

export class SimpleNotificationService<Model> extends CwtStore<SimpleNotificationState<Model>> {
    private _socket: Socket;
    private _injector = inject(Injector);

    constructor(
        private socketUrl: string,
        protected api: CwtJsonApiService,
        protected model: Type<Model>,
        protected options: SimpleNotificationContractOptions,
    ) {
        super({
            lastCheck: null,
            items: [],
            total: 0,
            unread: 0,
            initialized: false,
            loading: false,
        });
    }

    public init() {
        return forkJoin([
            this.getLastCheckAndUnreadNotifications(),
            this.getNotifications()
        ]).pipe(tap(([stats, notifications]) => {
            this.connectToSocket();
            this.setState(c => ({
                ...c,
                items: notifications.data,
                total: notifications.total,
                initialized: true,
                loading: false,
                lastCheck: stats.lastCheck,
                unread: stats.unread,
            }));
        }));
    }

    public nextPage() {
        const { items } = this.getValue();
        this.setLoading(true);
        return this.getNotifications(items.length ? items[items.length - 1] : undefined).pipe(
            mapPaginatedToArray(),
            tap(notifications => this.setState(c => ({
                ...c,
                loading: false,
                items: [...c.items, ...notifications]
            })))
        );
    }

    public clear() {
        if (this._socket) {
            this._socket.disconnect();
            this._socket = null;
        }
        this.setState(c => ({
            ...c,
            initialized: false,
            lastCheck: null,
            loading: false,
            items: [],
            total: 0,
            unread: 0
        }));
    }

    public check() {
        return this.createCheck().pipe(
            switchMap(date => this.countNotifSinceLastCheck(date).pipe(
                map(count => ({ count, date })),
                tap(({ count, date }) => this.setState(c => ({
                    ...c,
                    unread: count,
                    lastCheck: date
                })))
            ))
        )
    }

    protected connectToSocket() {
        if (this._socket) return;

        const auth = this._injector.get(CwtAuthStore);
        this._socket = io(formatURL(this.socketUrl, 'notification', this.options.notifGroup), {
            autoConnect: false,
            forceNew: false,
            query: { token: auth.jwtToken },
            transports: ['websocket', 'polling'],
        });

        console.log('connecting', formatURL(this.socketUrl, 'notification', this.options.notifGroup));
        this._socket.on('connect', () => console.log('connect'));
        this._socket.on('connected', () => console.log('connected'));
        this._socket.on('disconnect', () => console.log('disconnect'));

        this._socket.on('notification', (notification) => this.setState(c => ({
            ...c,
            items: [new this.model(notification), ...c.items],
            total: c.total + 1,
            unread: c.unread + 1
        })));

        this._socket.connect();
    }

    protected getLastCheckAndUnreadNotifications() {
        return this.getLastCheck().pipe(
            switchMap(date => this.countNotifSinceLastCheck(date).pipe(
                map(count => ({ unread: count, lastCheck: date }))
            ))
        );
    }

    protected countNotifSinceLastCheck(date: Date) {
        if (!date) return this.api.get<number>(formatURL(this.options.notifPath, 'count'));
        return this.api.get<number>(formatURL(this.options.notifPath, 'count'), { creationDate: { gt: date } });
    }

    protected getNotifications(after?: Model): Observable<CwtApiPageResponse<Model>> {
        return this.api.get<any>(this.options.notifPath, {
            _id: after ? { lt: getId(after) } : undefined,
        }, {
            options: {
                sort: formatSort([{ direction: 'desc', key: '_id' }]),
                limit: 10,
                page: 0
            }
        }).pipe(map(res => ({
            total: res.total ?? 0,
            data: (res.data ?? []).map((n: any) => new this.model(n))
        })));
    }

    protected getLastCheck(): Observable<Date> {
        return this.api.get<any>(this.options.notifCheckPath).pipe(
            map(res => res ? new Date(res.creationDate) : null)
        );
    }

    protected createCheck(): Observable<Date> {
        return this.api.post<any>(this.options.notifCheckPath).pipe(
            map(res => res ? new Date(res.creationDate) : null)
        );
    }
}