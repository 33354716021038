import { } from '@angular/animations/browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CwtCoreModule } from '@cawita/core-front';
import { CwtLoggedInGuard } from '@cawita/core-front/auth';
import { CwtZorroModule } from '@cawita/core-front/zorro';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { CwtAdminCoreConfig } from './configs/core-front.config';
import { AppUserModule } from '@shared/modules/user';
import { RecaptchaModule } from '@shared/widgets/features/common/recaptcha';
import { QuillModule } from 'ngx-quill';
import { CwtAdminQuillConfig } from './configs/quill.config';
import { initGoogleMaps } from '@shared/services/apis';
import { MediaViewerModule } from './widgets/features/common/media-viewer/media-viewer.module';
import { NotificationModule } from './modules/notification/notification.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CwtCoreModule.forRoot(CwtAdminCoreConfig),
    CwtZorroModule.forRoot(),
    AppUserModule.forRoot(),
    NotificationModule.forRoot(),
    QuillModule.forRoot(CwtAdminQuillConfig),
    RecaptchaModule.forRoot(environment.recaptchaSiteKey),
    MediaViewerModule.forRoot(),
    RouterModule.forRoot([
      { path: 'auth', loadChildren: () => import('./pages/auth').then(m => m.AuthModule) },
      { path: '', canActivate: [CwtLoggedInGuard], loadChildren: () => import('./pages/main').then(m => m.MainModule) },
      { path: '**', redirectTo: '/' }
    ])
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initGoogleMaps(environment.googlePlaceApiKey), multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
