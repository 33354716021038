import { Inject, Injectable } from '@angular/core';
import { getId } from '@cawita/core-front';
import { CwtApiFilter, CwtApiService, CwtCrudContract, CwtCrudService, CwtRequestConfig } from '@cawita/core-front/api';
import { Intervention, Mission, MissionProStatus } from '@shared/models';
import { Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AsProMissionApi {
    private basePath = 'as-pro/mine/mission';
    constructor(
        @Inject(CwtCrudContract.get(Mission, 'as-pro')) private missionCrud: CwtCrudService<Mission>,
        private api: CwtApiService
    ) { }

    public acceptMission(intervention: Intervention, comment?: string, config?: CwtRequestConfig): Observable<Mission> {
        return this.missionCrud.create({
            intervention: getId(intervention),
            proStatus: MissionProStatus.Accepted,
            proComment: comment
        }, config);
    }

    public refuseMission(intervention: Intervention, comment?: string, config?: CwtRequestConfig): Observable<Mission> {
        return this.missionCrud.create({
            intervention: getId(intervention),
            proStatus: MissionProStatus.Refused,
            proComment: comment
        }, config);
    }

    public createMission(body: any, config?: CwtRequestConfig): Observable<Mission> {
        return this.missionCrud.create(body, config);
    }

    createMissionSlot(slot: any, config?: CwtRequestConfig) {
        return this.api.post(`as-pro/mine/slot`, slot, config);
    }

    public getMissionsForInterventions(interventions: Intervention[], config?: CwtRequestConfig): Observable<Mission[]> {
        return this.missionCrud.getArray({ intervention: { in: interventions?.map(getId) } }, config);
    }

    public cancelMission(missionId: string) {
        return this.api.post(`${this.basePath}/${missionId}/pro-status/cancel`).pipe(
            map(mission => new Mission(mission))
        );
    }

    public finishMission(missionId: string, body?: any) {
        return this.api.post(`${this.basePath}/${missionId}/pro-status/finished`, body).pipe(
            map(mission => new Mission(mission))
        );
    }
}