import { CwtApiSchema, CwtProp, CwtSchema } from "@cawita/core-front/api";
import { UploadFile } from "./upload-file.model";

export enum MediaType {
    Video = 'video',
    Image = 'image'
}
@CwtSchema()
export class Media extends CwtApiSchema {
    @CwtProp() type: MediaType;
    @CwtProp({ ref: UploadFile }) file: UploadFile;
    @CwtProp() description: string; 
}
