import { Inject, Injectable } from '@angular/core';
import { formatURL, getId } from '@cawita/core-front';
import { CwtApiService, CwtCrudContract, CwtCrudService } from '@cawita/core-front/api';
import { map } from 'rxjs';
import { Product, StripeProduct } from '../../models';
import { AppleProduct } from '../../models/market/apple.model';
import { GoogleProduct } from '../../models/market/google.model';

@Injectable({ providedIn: 'root' })
export class ProductApiService {
    constructor(
        private api: CwtApiService,
        @Inject(CwtCrudContract.get(Product)) private productCrud: CwtCrudService<Product>
    ) { }

    public linkApple(product: Product | string, payload: any) {
        return this.productCrud.postAction(getId(product), 'apple-product', payload).pipe(
            map(res => new Product(res))
        );
    }

    public linkGoogle(product: Product | string, payload: any) {
        return this.productCrud.postAction(getId(product), 'google-product', payload).pipe(
            map(res => new Product(res))
        );
    }

    public linkStripe(product: Product | string, payload: any) {
        return this.productCrud.postAction(getId(product), 'stripe-product', payload).pipe(
            map(res => new Product(res))
        );
    }

    public unlinkStripe(product: Product | string, stripe: StripeProduct | string) {
        return this.api.delete(`product/${getId(product)}/stripe-product/${getId(stripe)}`).pipe(
            map(res => new Product(res))
        );
    }

    public unlinkApple(product: Product | string, apple: AppleProduct | string) {
        return this.api.delete(`product/${getId(product)}/apple-product/${getId(apple)}`).pipe(
            map(res => new Product(res))
        );
    }

    public unlinkGoogle(product: Product | string, apple: GoogleProduct | string) {
        return this.api.delete(`product/${getId(product)}/google-product/${getId(apple)}`).pipe(
            map(res => new Product(res))
        );
    }
}