import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'cwt-recaptcha-hint',
  templateUrl: './recaptcha-hint.component.html',
  styleUrls: ['./recaptcha-hint.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecaptchaHintComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
