import { Directive, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { Notification } from '@shared/models';
import { CwtDirectiveView } from '@cawita/core-front';
import { Subscription } from 'rxjs';
import { SimpleNotificationContract, SimpleNotificationService } from '@shared/services/apis';

export type UnreadNotificationContext = {
  $implicit: number;
}

@Directive({
  selector: '[cwtUnreadNotification]'
})
export class UnreadNotificationDirective implements OnInit, OnDestroy {
  private _ctx: UnreadNotificationContext = { $implicit: 0 };
  private _view = new CwtDirectiveView(this.vcRef, this.tRef, this._ctx);
  private _sub: Subscription;


  constructor(
    private vcRef: ViewContainerRef,
    private tRef: TemplateRef<any>,
    @Inject(SimpleNotificationContract.get(Notification, 'admin')) private adminNotif: SimpleNotificationService<Notification>,
  ) { }

  ngOnInit(): void {
    this._listenTo(this.adminNotif);
  }

  ngOnDestroy(): void {
    this._clear();
  }

  private _listenTo(store: SimpleNotificationService<unknown>) {
    this._clear();
    this._sub = store.state$.subscribe(state => {
      this._ctx.$implicit = state.unread ?? 0;
      this._view.ensureState(state.initialized && (state.unread ?? 0) > 0);
    });
  }

  private _clear() {
    this._sub?.unsubscribe();
    this._sub = null;
  }
}
