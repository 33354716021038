import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CwtCommonModule } from '@cawita/core-front';
import { CwtAssetModule } from '@cawita/core-front/asset';
import { CwtAuthModule } from '@cawita/core-front/auth';
import { CwtStateModule } from '@cawita/core-front/state';
import { OrderByPipe } from '@shared/pipes/order-by.pipe';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { CurrencyModule } from '../../widgets/features/common/currency/currency.module';
import { AppUserModule } from '../user';
import { FormatAddressPipe } from './format-address.pipe';
import { GetCoordinatesCenterPipe } from './get-coordinates-center.pipe';
import { LetResolvedDirective } from './let-resolved.directive';
import { PickFileDirective } from './pick-file.directive';
import { NgModelChangeDebouncedDirective } from './model-change-debounce.directive';
import { IncludeIdPipe } from '@shared/pipes/include-id.pipe';

@NgModule({
    imports: [],
    exports: [
        CommonModule,
        CwtStateModule,
        CwtCommonModule,
        CwtAuthModule,
        CwtAssetModule,
        RouterModule,
        NzTypographyModule,
        NzSpaceModule,
        NzCardModule,
        NzGridModule,
        NzButtonModule,
        NzIconModule,
        AppUserModule,
        PickFileDirective,
        FormatAddressPipe,
        OrderByPipe,
        IncludeIdPipe,
        GetCoordinatesCenterPipe,
        LetResolvedDirective,
        CurrencyModule,
        NgModelChangeDebouncedDirective
    ],
    declarations: [
        PickFileDirective,
        FormatAddressPipe,
        OrderByPipe,
        IncludeIdPipe,
        GetCoordinatesCenterPipe,
        LetResolvedDirective,
        NgModelChangeDebouncedDirective
    ],
    providers: [],
})
export class AppCommonModule { }
