import { Injectable } from '@angular/core';
import { isset } from '@cawita/core-front';
import { NotificationCheck } from '@shared/models';
import { map, switchMap } from 'rxjs/operators';
import { CwtApiFilter, CwtApiService, CwtRequestConfig} from '@cawita/core-front/api';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NotificationApi {
    constructor(private api: CwtApiService) { }

    public count(filters?: CwtApiFilter, config?: CwtRequestConfig): Observable<number> {
        return this.api.get(`notification/count`, filters, config);
    }

    public getLastCheck() { return this.api.get(`notification-check`).pipe(); }
    public createCheck() { return this.api.post(`notification-check`).pipe(); }
    public getLastCheckAndCount() {
        return this.getLastCheck().pipe(
            switchMap(check => this.countForCheck().pipe(
                map(count => ({
                    check,
                    count
                }))
            ))
        )
    }

    private countForCheck(check?: NotificationCheck) {
        if (isset(check)) return this.count({ creationDate: { } });
        return this.count({});
    }
}