<div *nzModalTitle>{{'view.media-preview' | translate}}</div>

<div class="media-container" [ngSwitch]="mediaType">
    <cwt-image *ngSwitchCase="'image'" [src]="src" width="100%" height="100%" fitting="contain">
        <cwt-loading-addon *cwtMediaAddon="'loading'"></cwt-loading-addon>
        <cwt-icon-addon *cwtMediaAddon="'placeholder'" icon="picture"></cwt-icon-addon>
        <cwt-icon-addon *cwtMediaAddon="'error'" icon="exclamation-circle"></cwt-icon-addon>
    </cwt-image>
    <cwt-video *ngSwitchCase="'video'" [controls]="true" [src]="src" width="100%" height="100%" fitting="contain">
        <cwt-loading-addon *cwtMediaAddon="'loading'"></cwt-loading-addon>
        <cwt-icon-addon *cwtMediaAddon="'placeholder'" icon="video-camera"></cwt-icon-addon>
        <cwt-icon-addon *cwtMediaAddon="'error'" icon="exclamation-circle"></cwt-icon-addon>
    </cwt-video>
</div>

<div *nzModalFooter>
    <button *ngIf="onDelete" nz-button nzType="primary" nzDanger (click)="callDelete()">{{'action.remove' | translate}}</button>
</div>