import { Inject, Injectable } from '@angular/core';
import { CwtApiPageResponse, CwtApiService, CwtCrudContract, CwtCrudService, CwtRequestConfig, mapArrayToPaginated } from '@cawita/core-front/api';
import { Pro } from '@shared/models';
import { Observable, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AsCustomerProApi {
    private basePath = 'as-customer/pro/';
    constructor(
        @Inject(CwtCrudContract.get(Pro, 'as-customer')) private proAsCustomerCrud: CwtCrudService<Pro>,
        private api: CwtApiService
    ) { }

    public getProGoWithin(filters: any, coords: number[], config?: CwtRequestConfig): Observable<CwtApiPageResponse<Pro>> {
        if (!coords?.length) return this.proAsCustomerCrud.getPage(filters, this._getRequestConfig(coords, config));

        return this.api.get(`${this.basePath}/go-within`, filters, this._getRequestConfig(coords, config)).pipe(
            mapArrayToPaginated(),
            tap(res => res.data = res.data.map(pro => new Pro(pro)))
        );
    }

    public getProAround(filters: any, coords: number[], config?: CwtRequestConfig): Observable<CwtApiPageResponse<Pro>> {
        if (!coords?.length) return this.proAsCustomerCrud.getPage(filters, this._getRequestConfig(coords, config));

        return this.api.get(`${this.basePath}/around`, filters, this._getRequestConfig(coords, config)).pipe(
            mapArrayToPaginated(),
            tap(res => res.data = res.data.map(pro => new Pro(pro)))
        );
    }

    private _getRequestConfig(coords?: number[], config?: CwtRequestConfig): CwtRequestConfig {
        return {
            ...config,
            options: {
                limit: 10,
                page: 0,
                ...config?.options,
                coord: coords?.join(',') ?? undefined,
            }
        };
    }
}