import { Pipe, PipeTransform } from '@angular/core';
import { Mission } from '../../models';
import { getMainMissionForCustomer } from './mission.utils';

@Pipe({
  name: 'mainMission'
})
export class MainMissionPipe implements PipeTransform {

  transform(missions: Mission[]): Mission {
    return getMainMissionForCustomer(missions);
  }

}
