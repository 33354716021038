import { Directive, inject, Injector, Input, TemplateRef } from '@angular/core';
import { AbstractControl, NgControl, NgModel } from '@angular/forms';

@Directive({ selector: '[cwtFormCardProvider]' })
export class FormCardProviderDirective {
    private _injector: Injector = inject(Injector);

    @Input() title: string | TemplateRef<void>;
    @Input() extra?: string | TemplateRef<void>;
    @Input() successTip?: string | TemplateRef<{ $implicit: AbstractControl | NgModel }>;
    @Input() warningTip?: string | TemplateRef<{ $implicit: AbstractControl | NgModel }>;
    @Input() errorTip?: string | TemplateRef<{ $implicit: AbstractControl | NgModel }>;
    @Input() validatingTip?: string | TemplateRef<{ $implicit: AbstractControl | NgModel }>;

    constructor() { }

    public getControl(): NgControl { return this._injector.get(NgControl, null); }
}