
import { Inject, Injectable } from '@angular/core';
import { asArray, getId, sameId } from '@cawita/core-front';
import { CwtApiService, CwtCrudContract, CwtCrudService, CwtRequestConfig } from '@cawita/core-front/api';
import { Job, Pro, Skill } from '@shared/models';

@Injectable({ providedIn: 'root' })
export class ProApi {
    constructor(
        @Inject(CwtCrudContract.get(Pro)) private proCrud: CwtCrudService<Pro>,
        private api: CwtApiService) { }

    deleteMedia(id, fileId) {
        return this.api.delete(`/pro/${id}/company-media/${fileId}`);
    }

    deletePro(credentials: any, config?: CwtRequestConfig) {
        return this.api.delete(`/as-pro/pro/mine`, { strongAuthenticationCredentials: credentials }, config);
    }

    public removeAdittionalSkill(pro: Pro, job: Job, skill: string) {
        return this.proCrud.update(getId(pro), {
            jobs: pro?.jobs?.map(getId),
            skills: pro?.skills?.map(getId),
            additionalSkills: this._getAdditionnalSkillsForReplacer(pro, job, skill)
        });
    }

    public replaceAdditionalSkillWithSkill(pro: Pro, job: Job, skill: string, replacer: Skill | Skill[]) {
        return this.proCrud.update(getId(pro), {
            jobs: pro?.jobs?.map(getId),
            skills: this._getSkillsForReplacer(pro, replacer),
            additionalSkills: this._getAdditionnalSkillsForReplacer(pro, job, skill)
        });
    }

    private _getSkillsForReplacer(pro: Pro, replacers: Skill | Skill[]) {
        const newSkills = [...(pro.skills ?? [])];
        asArray(replacers).forEach(replacer => {
            const alreadySelected = newSkills?.some(s => sameId(replacer, s));
            if (!alreadySelected) newSkills.push(replacer);
        })

        return newSkills.map(getId);
    }

    private _getAdditionnalSkillsForReplacer(pro: Pro, job: Job, skill: string) {
        if (!pro?.additionalSkills?.length) return [];
        return pro.additionalSkills.map(as => {
            if (!sameId(as.job, job)) return { job: getId(as.job), skills: as.skills };
            return { job: getId(as.job), skills: as.skills.filter(s => s !== skill) };
        }).filter(as => as?.skills?.length > 0);
    }
}

