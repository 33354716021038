import { Inject, Injectable } from '@angular/core';
import { formatURL } from '@cawita/core-front';
import { CwtApiPageResponse, CwtApiService, CwtCrudContract, CwtCrudService, CwtRequestConfig, mapArrayToPaginated, mapPaginatedToArray } from '@cawita/core-front/api';
import { Customer, Pro } from '@shared/models';
import { map, Observable, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AsProCustomerApi {
    private basePath = 'as-pro/';
    constructor(
        @Inject(CwtCrudContract.get(Customer, 'as-pro')) private customerAsProCrud: CwtCrudService<Customer>,
        private api: CwtApiService
    ) { }

    public getMyCustomer(proId: string, filters, config?: CwtRequestConfig) {
        return this.api.get(`${this.basePath}/${proId}/customer`, filters, config).pipe(
            mapArrayToPaginated(),
            tap(res => res.data = res.data.map(customer => new Customer(customer))))
    }

    public getCustomersTemp(filters, config?: CwtRequestConfig): Observable<CwtApiPageResponse<Customer>> {
        return this.api.get(`customer/`, filters, config).pipe(
            mapArrayToPaginated(),
            tap(res => res.data = res.data.map(customer => new Customer(customer))))
    }
}