import { AfterContentInit, ChangeDetectorRef, Component, ContentChild, Input, OnInit, Optional, SkipSelf, TemplateRef } from '@angular/core';
import { AbstractControl, FormControlDirective, FormControlName, NgControl, NgModel } from '@angular/forms';
import { takeUntilDestroyed } from '@cawita/core-front';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { NzFormControlStatusType } from 'ng-zorro-antd/form';
import { Observable, Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { FormCardProviderDirective } from './form-card-control.provider';

@Component({
  selector: 'cwt-form-card',
  templateUrl: './form-card.component.html',
  styleUrls: ['./form-card.component.less']
})
export class FormCardComponent implements OnInit, AfterContentInit {

  @Input() title: string | TemplateRef<void>;
  @Input() extra?: string | TemplateRef<void>;
  @Input() successTip?: string | TemplateRef<{ $implicit: AbstractControl | NgModel }>;
  @Input() warningTip?: string | TemplateRef<{ $implicit: AbstractControl | NgModel }>;
  @Input() errorTip?: string | TemplateRef<{ $implicit: AbstractControl | NgModel }>;
  @Input() validatingTip?: string | TemplateRef<{ $implicit: AbstractControl | NgModel }>;
  @ContentChild(NgControl, { static: false }) defaultValidateControl?: FormControlName | FormControlDirective;

  status: NzFormControlStatusType = '';

  validateChanges: Subscription = Subscription.EMPTY;
  validateControl: AbstractControl | NgModel | null = null;
  validateString: string | null = null;

  innerTip: string | TemplateRef<{ $implicit: AbstractControl | NgModel }> | null = null;

  private _control: NgControl;
  public get control(): NgControl {
    return this._control;
  }
  public set control(v: NgControl) {
    this._control = v;
  }

  constructor(
    @SkipSelf() @Optional() private provider: FormCardProviderDirective,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

  @Input()
  set validateStatus(value: string | AbstractControl | FormControlName | NgModel) {
    if (value instanceof AbstractControl || value instanceof NgModel) {
      this.validateControl = value;
      this.validateString = null;
      this.watchControl();
    } else if (value instanceof FormControlName) {
      this.validateControl = value.control;
      this.validateString = null;
      this.watchControl();
    } else {
      this.validateString = value;
      this.validateControl = null;
      this.setStatus();
    }
  }

  ngAfterContentInit(): void {
    if (!this.validateControl && !this.validateString) {
      const provided = this.provider?.getControl();
      if (provided instanceof NgControl) {
        this.validateStatus = provided?.control;
      } else if (this.defaultValidateControl instanceof FormControlDirective) {
        this.validateStatus = this.defaultValidateControl.control;
      } else {
        this.validateStatus = this.defaultValidateControl!;
      }
    }
  }

  private watchControl(): void {
    this.validateChanges.unsubscribe();
    /** miss detect https://github.com/angular/angular/issues/10887 **/
    if (this.validateControl && this.validateControl.statusChanges) {
      this.validateChanges = (this.validateControl.statusChanges as Observable<NzSafeAny>)
        .pipe(
          startWith(null as any),
          takeUntilDestroyed(this)
        ).subscribe(() => {
          this.setStatus();
          this.cdRef.markForCheck();
        });
    }
  }

  private setStatus(): void {
    this.status = this.getControlStatus(this.validateString);
    this.innerTip = this.getInnerTip(this.status);
  }

  private getControlStatus(validateString: string | null): NzFormControlStatusType {
    if (validateString === 'warning' || this.validateControlStatus('INVALID', 'warning')) return 'warning';
    else if (validateString === 'error' || this.validateControlStatus('INVALID')) return 'error';
    else if (validateString === 'validating' || validateString === 'pending' || this.validateControlStatus('PENDING')) return 'validating';
    else if (validateString === 'success' || this.validateControlStatus('VALID')) return 'success';
    else return '';
  }

  private validateControlStatus(validStatus: string, statusType?: NzFormControlStatusType): boolean {
    if (!this.validateControl) return false;
    const { dirty, touched, status } = this.validateControl;
    return (
      (!!dirty || !!touched) &&
      (statusType ? this.validateControl.hasError(statusType) : status === validStatus)
    );
  }

  private getInnerTip(status: NzFormControlStatusType): string | TemplateRef<{ $implicit: AbstractControl | NgModel }> | null {
    switch (status) {
      case 'error': return this.provider?.errorTip || this.errorTip || null;
      case 'validating': return this.provider?.validatingTip || this.validatingTip || null;
      case 'success': return this.provider?.successTip || this.successTip || null;
      case 'warning': return this.provider?.warningTip || this.warningTip || null;
      default: return null;
    }
  }
}
