import { Injectable } from '@angular/core';
import { CwtApiService } from '@cawita/core-front/api';
import { map, Observable, pluck } from 'rxjs';
import { SharedProConfig } from '../../models';

@Injectable({ providedIn: 'root' })
export class SettingApi {
    constructor(private api: CwtApiService) { }

    public getProConfig(): Observable<SharedProConfig> {
        return this.api.get(`setting`).pipe(
            pluck('proConfig'),
            map(c => new SharedProConfig(c))
        );
    }

    public updateProConfig(config: SharedProConfig) {
        return this.api.patch(`setting`, { proConfig: config }).pipe(
            pluck('proConfig'),
            map(c => new SharedProConfig(c))
        );
    }
}