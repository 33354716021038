import { CwtValueAccessor, findValueInObject, isset } from "@cawita/core-front";
import { CwtApiEntity, CwtProp, CwtSchema } from "@cawita/core-front/api";
import { Address } from "./address.model";

@CwtSchema()
export class LocalizedAddress extends CwtApiEntity {
    @CwtProp({ ref: Address }) address: Address;
    @CwtProp() coord: [lng: number, lat: number];
}

export function virtualLocalizedAddress(
    addressKey: CwtValueAccessor<any, Address> = 'address',
    coordKey: CwtValueAccessor<any, [number, number]> = 'coord'
) {
    return (host: any) => {
        const address = findValueInObject(host, addressKey);
        const coord = findValueInObject(host, coordKey);
        if (!isValidAddress(address) || !isValidCoordinates(coord)) return null;
        return new LocalizedAddress({ address, coord });
    }
}

export function isValidAddress(address: any) {
    if (!isset(address)) return false;
    if (typeof address !== 'object') return false;
    if (isset(address?.line1) && typeof address?.line1 !== 'string') return false;
    if (isset(address?.line2) && typeof address?.line2 !== 'string') return false;
    if (isset(address?.city) && typeof address?.city !== 'string') return false;
    if (isset(address?.zip) && typeof address?.zip !== 'string') return false;
    if (isset(address?.country) && typeof address?.country !== 'string') return false;
    return true;
}

export function isValidCoordinates(coord: any) {
    if (!isset(coord)) return false;
    if (typeof coord !== 'object') return false;
    if (!Array.isArray(coord)) return false;
    if (coord.length !== 2) return false;
    return true;
}