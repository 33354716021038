import { Component, OnInit } from '@angular/core';
import { CwtMediaType } from '@shared/modules/media/media.utils';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  templateUrl: './media-viewer.component.html',
  styleUrls: ['./media-viewer.component.less']
})
export class MediaViewerComponent implements OnInit {
  public mediaType: CwtMediaType;
  public src: string | File;
  public poster: string;
  public name: string;
  public onDelete: () => void = null;

  constructor(private modal: NzModalRef) { }

  ngOnInit(): void {
  }

  callDelete() {
    this.modal.close();
    this.onDelete();
  }

}
