// @index(['./!(*.spec).ts', './*/index.ts'], f => `export * from '${f.path.replace(/\/index$/, '')}';`)
export * from './as-customer-intervention.api';
export * from './as-customer-mission.api';
export * from './as-customer-pro.api';
export * from './rating.api';
export * from './as-pro-customer';
export * from './as-pro-intervention.api';
export * from './as-pro-mission.api';
export * from './as-pro-pro.api';
export * from './as-pro-showroom.api';
export * from './contracts';
export * from './google.api';
export * from './notification.api';
export * from './pro.api';
export * from './product.api';
export * from './setting.api';
export * from './showroom.api';
export * from './user.api';
// @endindex