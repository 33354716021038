import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NzModalService } from 'ng-zorro-antd/modal';
import { CwtMediaViewerOptions, ICwtMediaViewerAdapter } from '@shared/modules/media/media-viewer-adapter';
import { MediaViewerComponent } from './media-viewer.component';

@Injectable()
export class MediaViewerService implements ICwtMediaViewerAdapter {
    constructor(private modal: NzModalService) { }

    async viewMedia(options: CwtMediaViewerOptions): Promise<void> {
        this.modal.create({
            nzContent: MediaViewerComponent,
            nzFooter: null,
            nzComponentParams: {
                mediaType: options?.type,
                src: options?.src,
                name: options?.name,
                onDelete: options?.onDelete,
            }
        });
    }

}