import { Directive, Input, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { CwtDirectiveView, hasSomeChanges } from '@cawita/core-front';
import { Subscription } from 'rxjs';
import { Intervention, InterventionFlow } from '../../../models';

@Directive({
  selector: '[cwtInterventionHasManyMissions]'
})
export class InterventionHasManyMissionsDirective {

  private _view = new CwtDirectiveView(this.vcRef, this.tRef, {});
  private _elseView = new CwtDirectiveView(this.vcRef, null, {});
  private _sub: Subscription;

  @Input('cwtInterventionHasManyMissions') intervention: Intervention;
  @Input('cwtInterventionHasManyMissionsElse') set elseTemplate(v: TemplateRef<any>) {
    this._elseView.setTemplate(v);
  }

  constructor(
    private vcRef: ViewContainerRef,
    private tRef: TemplateRef<any>
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasSomeChanges(changes, ['intervention'], true)) this._update();
  }

  ngOnDestroy(): void {
    this._sub?.unsubscribe();
  }

  private _update() {
    const flowMatches = [InterventionFlow.Emergency, InterventionFlow.Quote].includes(this.intervention?.flow);
    this._view.ensureState(flowMatches);
    this._elseView.ensureState(!flowMatches);
  }

}
