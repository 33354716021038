import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { normalizeString } from '@cawita/core-front';

@Component({
  selector: 'cwt-avatar-addon',
  templateUrl: './avatar-addon.component.html',
  styleUrls: ['./avatar-addon.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarAddonComponent implements OnInit {

  @Input() text: string;
  @Input() length: number = 2;

  constructor() { }

  ngOnInit(): void { }

}
