import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp, CwtVirtual } from "@cawita/core-front/api";
import { Address } from "./address.model";
import { CustomerAddress } from "./customer-address.model";
import { Customer } from "./customer.model";
import { Job } from "./job.model";
import { LocalizedAddress, virtualLocalizedAddress } from "./localized-address.model";
import { Media } from "./media.model";
import { Mission } from "./mission.model";
import { Pro } from "./pro.model";
import { Skill } from "./skill.model";
import { User } from "./user.model";

export enum InterventionStatus {
    Pending = 'pending',
    Finished = 'finished',
    ProChosen = 'proChosen',
    Canceled = 'canceled'
}

export enum InterventionFlow {
    Emergency = 'emergency',
    Direct = 'direct',
    Quote = 'quote'
}

export type InterventionStatusDate = {
    user: Partial<User>;
    date: string;
}

export type InterventionMeta = {
    customerPending: number;
    lastPending?: Mission;
}

@CwtModel({ name: 'intervention' })
@CwtCrudContract({ path: 'mine/intervention', pathPrefix: 'as-customer', })
@CwtCrudContract({ path: 'mine/intervention', pathPrefix: 'as-pro', })
@CwtCrudContract({ path: '/intervention', searchMapper: (s) => ({ 'name': { regexp: s } }), })
export class Intervention extends CwtApiEntity {
    @CwtProp() name: string;
    @CwtProp() flow: InterventionFlow;
    @CwtProp() status: InterventionStatus;
    @CwtProp() statusDates: Record<InterventionStatus, InterventionStatusDate>;
    @CwtProp({ ref: Date }) endDate: Date;
    @CwtProp({ ref: Customer }) customer: Customer;
    @CwtProp() address: Address;
    @CwtProp() coord: [number, number];
    @CwtProp({ ref: CustomerAddress }) customerAddress: CustomerAddress;
    @CwtProp({ ref: Job }) job: Job;
    @CwtProp({ ref: Skill }) skills: Skill[];
    @CwtProp() description: string;
    @CwtProp() medias: Media[];
    @CwtProp() isOpen: boolean;
    @CwtProp() maxAnswers: number;
    @CwtProp() minNotation: number;
    @CwtProp() minExperience: number;
    @CwtProp({ ref: Pro }) targettedPros: Pro[];
    @CwtProp() isPublic: boolean;
    @CwtProp() isSuspended: boolean;

    public mission: Mission;
    public missions: Mission[];
    public meta: InterventionMeta;

    @CwtVirtual({ get: virtualLocalizedAddress() }) public workarea: LocalizedAddress;
}