import { CwtApiSchema, CwtCrudContract, CwtModel, CwtProp, CwtSchema } from "@cawita/core-front/api";
import { Pro } from "./pro.model";

export enum ProConfigType {
    Default = 'default',
    System = 'system',
    Admin = 'admin'
}

export enum ProConfigSubType {
    Default = 'default',
    Admin = 'admin',
    Free = 'free',
    Stripe = 'stripe',
    Apple = 'apple',
    Google = 'google'
}
@CwtSchema()
export class ProConfigFeatures extends CwtApiSchema {
    @CwtProp({ default: 0 }) missionQuotaPerMonth: number;
}

@CwtSchema()
export class SharedProConfig extends ProConfigFeatures {
    @CwtProp({ default: ProConfigType.Default }) type: ProConfigType;
    @CwtProp({ default: ProConfigSubType.Default }) subType: ProConfigSubType;
    @CwtProp({ default: false }) isSubscribed: boolean;
}


@CwtModel({ name: 'pro-config' })
@CwtCrudContract({ path: 'mine/pro-config', pathPrefix: 'as-pro' })
export class ProConfig extends SharedProConfig {
    @CwtProp({ ref: Pro }) pro: Pro;
}

@CwtSchema()
export class BestProConfigValue<T> {
    @CwtProp() from: ProConfigSubType;
    @CwtProp() value: T;
}

@CwtSchema()
export class BestProConfig extends CwtApiSchema {
    @CwtProp({ ref: BestProConfigValue }) missionQuotaPerMonth: BestProConfigValue<number>;
    @CwtProp({ ref: BestProConfigValue }) isSubscribed: BestProConfigValue<boolean>;
}