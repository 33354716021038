import { CwtApiEntity, CwtProp, CwtSchema } from "@cawita/core-front/api";
import { CwtAssetObject } from "@cawita/core-front/asset";

@CwtSchema()
export class UploadFile extends CwtApiEntity implements CwtAssetObject {
    @CwtProp() name: string;
    @CwtProp() originalName: string;
    @CwtProp() extension: string;
    @CwtProp() isPrivate: boolean;
    @CwtProp() resourceId: string;
    @CwtProp() tag: string;
    @CwtProp() path: string[];
    @CwtProp() thumbnailPath: string[];
}