import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cwt-icon-addon',
  templateUrl: './icon-addon.component.html',
  styleUrls: ['./icon-addon.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconAddonComponent implements OnInit {

  @Input() icon: string = 'picture';
  @Input() ripple = false;

  constructor() { }

  ngOnInit(): void {
  }

}
