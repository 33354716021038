import { Pipe, PipeTransform } from '@angular/core';
import { isset } from '@cawita/core-front';
import { TranslateService } from '@ngx-translate/core';
import { Address } from '../../models';
import { TranslationUtilsService } from '../../services/utils/translation-utils.service';

@Pipe({
  name: 'formatAddress',
  pure: false
})
export class FormatAddressPipe implements PipeTransform {

  constructor(private translationUtils: TranslationUtilsService) { }

  transform(address: Partial<Address>, format: 'long' | 'city'): any {
    if (!isset(address)) return '';
    switch (format) {
      case 'city': return this._formatCity(address);
      default: return this._formatLong(address);
    }
  }

  private _formatLong(address: Partial<Address>) {
    const country: string = this.translationUtils.translateEnumValue('countries', address?.country);
    const cityZipPart = [address.zip, address.city].join(' ');
    const segments = [address.line1, cityZipPart, country].map(s => s?.trim()).filter(s => s?.length);
    return segments.filter(s => s?.length).join(', ');
  }

  private _formatCity(address: Partial<Address>) {
    return [address.zip, address.city].filter(s => s?.length).map(s => s.trim()).join(', ');
  }
}
