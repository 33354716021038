import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { selectFile } from '@cawita/core-front';

@Directive({
  selector: '[cwtPickFile]',
  host: {
    'style': 'cursor: pointer;'
  }
})
export class PickFileDirective {

  @Input('cwtPickFile') accept?: string;
  @Input('cwtPickFileMultiple') multiple: boolean = false;
  @Input('cwtPickFileDisabled') disabled = false;

  @Output() file = new EventEmitter<File | File[]>();

  constructor() { }

  @HostListener('click', ['$event'])
  public onClick($event: Event) {
    if (this.disabled) return;

    $event?.stopPropagation();
    $event?.preventDefault();
    if (this.multiple) selectFile(this.accept, true).then(files => this.file.emit(files));
    else selectFile(this.accept, false).then(file => this.file.emit(file));
  }

}
