import { CwtApiEntity, CwtModel, CwtProp, CwtSubCrudContract } from "@cawita/core-front/api";
import { User } from "./user.model";

export enum LockType {
    Admin = 'admin',
    TooManyPasswordAttempts = 'tooManyPasswordAttempts'
}

@CwtModel({ name: 'user-lock' })
@CwtSubCrudContract({ parent: User, parentPath: 'user', subPath: 'user-lock' })
export class UserLock extends CwtApiEntity {
    @CwtProp({ ref: User }) user: User;
    @CwtProp() type: LockType;
    @CwtProp({ ref: Date }) lockUntilDate: Date;
    @CwtProp() reason: string;
}