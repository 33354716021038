import { Injectable } from '@angular/core';
import { formatURL } from '@cawita/core-front';
import { CwtApiService, CwtRequestConfig } from '@cawita/core-front/api';

@Injectable({ providedIn: 'root' })
export class UserApi {
    constructor(private api: CwtApiService) { }

    public updatePassword(id: string, body: any, config: CwtRequestConfig) {
        return this.api.patch(formatURL('user', id, 'password'), body, config);
    }

    resendPassword(payload){
        return this.api.post('/user/reset/send-email',payload); 
    }
}