import { ModuleWithProviders, NgModule } from '@angular/core';
import { CWT_AUTH_LISTENER } from '@cawita/core-front/auth';
import { IfLoggedDirective } from './if-logged.directive';
import { IsLoggedDirective } from './is-logged.directive';
import { IsNotLoggedUserDirective } from './is-not-logged-user.directive';
import { LoggedUserDirective } from './logged-user.directive';
import { UserAuthListener } from './user-auth-listener';

@NgModule({
    imports: [],
    exports: [
        LoggedUserDirective,
        IsLoggedDirective,
        IfLoggedDirective,
        IsNotLoggedUserDirective,
    ],
    declarations: [
        LoggedUserDirective,
        IfLoggedDirective,
        IsLoggedDirective,
        IsNotLoggedUserDirective,
    ],
    providers: [],
})
export class AppUserModule {
    static forRoot(): ModuleWithProviders<AppUserModule> {
        return {
            ngModule: AppUserModule,
            providers: [
                { provide: CWT_AUTH_LISTENER, useClass: UserAuthListener, multi: true }
            ]
        };
    }
}
