import { ChangeDetectorRef, Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { CwtDirectiveView, isset } from '@cawita/core-front';
import { CwtAuthStore } from '@cawita/core-front/auth';
import { Subscription } from 'rxjs';

export type IsLoggedContext = {
    $implicit: boolean;
}

@Directive({ selector: '[cwtIsLogged]' })
export class IsLoggedDirective implements OnDestroy {
    private _sub: Subscription;
    private _ctx: IsLoggedContext = { $implicit: null };
    private _view = new CwtDirectiveView(this.vcRef, this.tRef, this._ctx);

    constructor(
        private tRef: TemplateRef<IsLoggedContext>,
        private vcRef: ViewContainerRef,
        private auth: CwtAuthStore,
        private cdRef: ChangeDetectorRef
    ) {

        this._sub = this.auth.stableState$.subscribe({
            next: (state) => {
                this._ctx.$implicit = isset(state.jwtToken);
                this._view.ensureState(true);
                this.cdRef.markForCheck();
            }
        });
    }

    ngOnDestroy(): void {
        this._sub?.unsubscribe();
    }
}