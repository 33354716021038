export const environment = {
  production: false,
  apiUrl: 'https://api.kaarlo.dev.cawita.com',
  assetUrl: 'https://assets.kaarlo.dev.cawita.com',
  socketUrl: 'https://api.kaarlo.dev.cawita.com/',
  protectedAssetPath: 'private',
  publicAssetPath: 'public',
  recaptchaSiteKey: '6LfgUtoaAAAAAAxFBLNU6FcAhEboBvEl6G0ZBiuR',
  googlePlaceApiKey: 'AIzaSyAYgqg-pzzLCdzavo-asCuddX1uz81HdY8'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
