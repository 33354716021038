import { CwtApiSchema, CwtProp, CwtSchema } from "@cawita/core-front/api";
import { InterventionFlow } from "../intervention.model";

@CwtSchema()
export class PerFlowStat extends CwtApiSchema {
    @CwtProp() flow: InterventionFlow;
    @CwtProp() count: number;
}

@CwtSchema()
export class InterventionStat extends CwtApiSchema {
    @CwtProp() count: number;
    @CwtProp({ ref: PerFlowStat }) perFlow: PerFlowStat[];
}