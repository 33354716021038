import { ChangeDetectorRef, Directive, Inject, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { Intervention, Mission } from '../../../models';
import { AsCustomerInterventionApi, AsProInterventionApi } from '../../../services/apis';
import { CwtDirectiveView, getId, hasSomeChanges, sameId } from '@cawita/core-front';
import { CwtCrudContract, CwtCrudService } from '@cawita/core-front/api';
import { Observable, map, of } from 'rxjs';
import { coerceArray } from '@angular/cdk/coercion';
import { getMainMissionForCustomer } from '../mission.utils';

export type WithMissionContext = {
  $implicit: Mission[];
  loaded: boolean;
  mainMission: Mission;
}

@Directive({
  selector: '[cwtWithMission]'
})
export class WithMissionDirective implements OnChanges {
  static ngTemplateContextGuard(directive: WithMissionDirective, context: unknown): context is WithMissionContext {
    return true;
  }

  private _ctx: WithMissionContext = { $implicit: [], loaded: false, mainMission: null};
  private _view = new CwtDirectiveView(this.vcRef, this.tRef, this._ctx);

  @Input('cwtWithMission') intervention?: Intervention | Intervention[];
  @Input('cwtWithMissionView') view: 'pro' | 'customer' = 'customer';

  constructor(
    private vcRef: ViewContainerRef,
    private tRef: TemplateRef<WithMissionContext>,
    private asProIntervention: AsProInterventionApi,
    private asCustomerIntervention: AsCustomerInterventionApi,
    @Inject(CwtCrudContract.get(Mission, 'as-customer')) private asCustomerMissionCrud: CwtCrudService<Mission>,
    @Inject(CwtCrudContract.get(Mission, 'as-pro')) private asProMissionCrud: CwtCrudService<Mission>,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasSomeChanges(changes, ['intervention'], true)) this._fetch();
  }

  private _fetch() {
    this._ctx.loaded = false;
    this._ctx.$implicit = [];
    this._view.ensureState(true);
    this._getArray().subscribe(missions => {
      this._ctx.$implicit = missions;
      this._ctx.loaded = true;
      this._ctx.mainMission = this._getMainMission(missions);
      this._view.ensureState(true);
      this.cdRef.markForCheck();
    });
  }

  public replace(mission: Mission) {
    const arr = [...this._ctx.$implicit];
    const missionIndex = arr.findIndex(m => sameId(m, mission));
    if (missionIndex < 0) return;
    arr[missionIndex] = mission;
    this._ctx.$implicit = arr;
    this._ctx.mainMission = this._getMainMission(arr);
    this.cdRef.markForCheck();
  }

  private _getArray(): Observable<Mission[]> {

    if (!this.intervention) return of([]);
    if (Array.isArray(this.intervention)) {
      if (!this.intervention?.length) return of([]);
      if (this.view === 'customer') return this.asCustomerMissionCrud.getArray({ _id: { in: this.intervention.map(getId) } });
      return this.asProMissionCrud.getArray({ _id: { in: this.intervention.map(getId) } });
    }

    if (this.view === 'customer') return this.asCustomerIntervention.getMissionForIntervention(getId(this.intervention));
    return this.asProIntervention.getMissionForIntervention(getId(this.intervention)).pipe(map(r => coerceArray(r)));

  }

  private _getMainMission(missions:Mission[]):Mission {
    if (this.view === 'customer') return getMainMissionForCustomer(missions)
    return missions[0];
  }
}
