import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp, CwtSubCrudContract } from "@cawita/core-front/api";
import { Customer } from "./customer.model";
import { Intervention } from "./intervention.model";
import { Pro } from "./pro.model";


export enum MissionProStatus {
    Accepted = 'accepted',
    Refused = 'refused',
    Canceled = 'canceled',
    Finished = 'finished'
}

export enum MissionCustomerStatus {
    Accepted = 'accepted',
    Refused = 'refused',
    Pending = 'pending',
    Canceled = 'canceled',
    Finished = 'finished'
}


@CwtModel({ name: 'mission' })
@CwtCrudContract({ path: 'mission' })
@CwtCrudContract({ path: 'mine/mission', pathPrefix: 'as-pro' })
@CwtCrudContract({ path: 'mine/mission', pathPrefix: 'as-customer' })

export class Mission extends CwtApiEntity {
    @CwtProp({ ref: Intervention }) intervention: Intervention;
    @CwtProp({ ref: Pro }) pro: Pro;
    @CwtProp({ ref: Customer }) customer: Customer;
    @CwtProp() proStatus: MissionProStatus;
    @CwtProp() customerStatus: MissionCustomerStatus;
    @CwtProp() proComment: string;
    @CwtProp() isSuspended: boolean;
}

export type MissionActionType = 'accept' | 'refuse' | 'finish' | 'cancel';