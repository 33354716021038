import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencyComponent } from './currency.component';
import { CwtCommonModule } from '@cawita/core-front';



@NgModule({
  declarations: [
    CurrencyComponent
  ],
  imports: [
    CommonModule,
    CwtCommonModule,
  ],
  exports: [
    CurrencyComponent
  ]
})
export class CurrencyModule { }
