import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp, CwtSchema, CwtVirtual } from "@cawita/core-front/api";
import { ProCompanyType, ProDocument } from "./pro.model";
import { UploadFile } from "./upload-file.model";
import { DocumentType } from "./pro.model";
import { Job } from "./job.model";

export enum ProRequestStatus {
    Submitted = 'submitted',
    Validated = 'validated',
    Refused = 'refused',
    NeedChange = 'needChange'
};


@CwtSchema()
export class ProRequestDocument {
    @CwtProp() type: DocumentType;
    @CwtProp({ ref: UploadFile }) file: UploadFile;
}
@CwtModel({ name: 'pro-request' })
@CwtCrudContract({
    path: '/pro-request',
    searchMapper: (s) => ({
        or: [
            { 'companyName': { regexp: s } },
        ]
    }),
})


export class ProRequest extends CwtApiEntity {
    @CwtProp() firstName: string;
    @CwtProp() lastName: string;
    @CwtProp() email: string;
    @CwtProp() phone: string;
    @CwtProp() status: ProRequestStatus;
    @CwtProp() statusDates: any;
    @CwtProp() adminComment: string;
    @CwtProp() companyId: string;
    @CwtProp() tvaNumber: string;
    @CwtProp() companyName: string;
    @CwtProp() companyType: ProCompanyType;
    @CwtProp({ ref:Job }) jobs:Job[];
    @CwtProp({ ref: ProDocument }) documents: ProDocument[];


    public getDocumentOfType(type: DocumentType) {
        return this.documents?.find(d => d?.type === type);
    }
}
