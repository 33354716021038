import { CwtCrudContract, CwtModel, CwtProp, CwtSubCrudContract, CwtVirtual } from "@cawita/core-front/api";
import { BaseRating, sumRate } from "./rating.model";
import { Pro } from "./pro.model";

export enum ProRateProps {
    professionalism = 'professionalism',
    qualityPrice = 'qualityPrice',
    quickness = 'quickness'
}

@CwtModel({ name: 'pro-rate' })
@CwtCrudContract({ path: 'pro-rate' })
@CwtCrudContract({ path: '/mine/pro-rate', pathPrefix: 'as-pro' })
@CwtSubCrudContract({ parent: Pro, parentPath: '/pro', subPath: 'pro-rate', pathPrefix: 'as-customer' })
export class ProRate extends BaseRating {
    @CwtProp() professionalism: number;
    @CwtProp() qualityPrice: number;
    @CwtProp() quickness: number;

    @CwtVirtual({ get: (model) => sumRate(model?.professionalism, model?.qualityPrice, model?.quickness) }) rate: number;
}