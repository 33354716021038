import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp, CwtSchema, CwtVirtual } from "@cawita/core-front/api";
import { Address } from "./address.model";
import { Job } from "./job.model";
import { LocalizedAddress, virtualLocalizedAddress } from "./localized-address.model";
import { Media } from "./media.model";
import { ProRateProps } from "./pro-rate.model";
import { BaseRatingMeta } from "./rating.model";
import { Skill } from "./skill.model";
import { UploadFile } from "./upload-file.model";
import { User } from "./user.model";

export enum ProStatus {
    Pending = 'pending',
    Validated = 'validated',
    Refused = 'refused',
    NeedChange = 'needChange'
}

export enum ProCompanyType {
    SARL = 'sarl',
    SAS = 'sas',
    ASSOCIATEDSARL = 'associatedSarl',
    SASU = 'sasu',
    EI = 'ei',
    AE = 'ae',
    OHTER = 'other',
}
export const COMPANY_TYPES_AS_ARRAY = Object.values(ProCompanyType);

export enum DocumentType {
    Kbis = 'kbis',
    Identity = 'identity',
    Identity_2 = 'identity_2',
    Insurance = 'insurance',
    Rib = 'rib'
}
export const DOCUMENT_TYPE_AS_ARRAY = Object.values(DocumentType);

@CwtSchema()
export class AdditionalSkill {
    @CwtProp({ ref: Job }) job: Job;
    @CwtProp() skills: string[];
}

@CwtSchema()
export class ProDocument {
    @CwtProp() type: DocumentType;
    @CwtProp({ ref: UploadFile }) file: UploadFile;
}

@CwtSchema()
export class ProAcceptedFlows {
    @CwtProp() direct: boolean;
    @CwtProp() quote: boolean;
    @CwtProp() emergency: boolean;
}

@CwtModel({ name: 'pro' })
@CwtCrudContract({ path: 'pro', pathPrefix: 'as-pro' })
@CwtCrudContract({
    path: '/pro',
    searchMapper: (s) => ({
        or: [
            { 'companyName': { regexp: s } },
        ]
    }),
})
@CwtCrudContract({ path: 'pro', pathPrefix: 'as-customer' })
@CwtCrudContract({ path: 'pro', pathPrefix: 'as-customer/mine' })

export class Pro extends CwtApiEntity {
    @CwtProp({ ref: User }) owner: User;
    @CwtProp() adminStatus: ProStatus;
    @CwtProp() adminComment: string;
    @CwtProp({ ref: UploadFile }) logo: UploadFile;
    @CwtProp() companyId: string;
    @CwtProp() companyName: string;
    @CwtProp() companyType: ProCompanyType;
    @CwtProp() tvaNumber: string;
    @CwtProp({ ref: Address }) address: Address;
    @CwtProp() radius: number;
    @CwtProp() coord: number[]; //TODO: DEFINE
    @CwtProp() experience: number;
    @CwtProp() description: string;
    @CwtProp({ ref: Media }) companyMedias: Media[];
    @CwtProp({ ref: ProDocument }) documents: ProDocument[];
    @CwtProp({ ref: Job }) jobs: Job[];
    @CwtProp({ ref: Skill }) skills: Skill[];
    @CwtProp() additionalSkills: AdditionalSkill[];
    @CwtProp({ ref: ProAcceptedFlows }) acceptedFlows: ProAcceptedFlows;
    @CwtProp() isInBook: boolean;
    @CwtProp({ ref: BaseRatingMeta }) rate: BaseRatingMeta<typeof ProRateProps>;
    @CwtProp() isDeleted: boolean;

    @CwtVirtual({ get: virtualLocalizedAddress() }) public workarea: LocalizedAddress;

    public getDocumentOfType(type: DocumentType) {
        return this.documents?.find(d => d?.type === type);
    }
}
