import { Injectable } from '@angular/core';
import { isset } from '@cawita/core-front';
import { CwtApiService, CwtRequestConfig } from '@cawita/core-front/api';
import { LocalizedAddress } from '../../models/localized-address.model';
import { Loader } from "@googlemaps/js-api-loader";

export function initGoogleMaps(apiKey: string) {
  return () => {
    const loader = new Loader({ apiKey: apiKey, version: "weekly", });
    return () => {
      loader.load();
    };
  }
}

@Injectable({ providedIn: 'root' })
export class GoogleApi {
  constructor(private api: CwtApiService) { }

  public async load() {
  }

  public placeDetails(place_id: string, filters?, config?: CwtRequestConfig) {
    return this.api.get<any>(`/google/places/detail/${place_id}`, filters, config)
  }

  public geocodeAddress(address: string, config?: CwtRequestConfig) {
    return this.api.get<any[]>('/google/places/autocomplete', { input: address }, config)
  }

  public reverseGeocode(coord: string, config?: CwtRequestConfig) {
    return this.api.get<any[]>('/google/places/reverse', {
      latlng: coord,
      location_type: ["ROOFTOP", "RANGE_INTERPOLATED", "GEOMETRIC_CENTER"]
    }, config)
  }
}

export function formatAddressFromGooglePlaceDetails(placeDetails, current?: LocalizedAddress): LocalizedAddress {
  const address_street_number = _getComponentByType(placeDetails, "street_number")?.long_name ?? '';
  const address_route = _getComponentByType(placeDetails, "route")?.long_name ?? "";
  const address_city = _getComponentByType(placeDetails, "locality")?.long_name ?? "";
  const address_zip = _getComponentByType(placeDetails, "postal_code")?.long_name ?? "";
  const address_country = _getComponentByType(placeDetails, "country")?.short_name ?? "";
  const coord: [number, number] = _getGeometryAsArray(placeDetails);

  return new LocalizedAddress({
    address: {
      line1: `${address_street_number} ${address_route}`,
      line2: current?.address?.line2 ?? null,
      zip: address_zip,
      city: address_city,
      country: address_country
    },
    coord: coord
  });
}

function _getComponentByType(data: any, type: string) {
  if (!isset(data)) return null;
  if (!data?.address_components?.length) return null;
  return data?.address_components.find((component: any) => component?.types?.includes(type));
}

function _getGeometryAsArray(data: any): [lng: number, lat: number] {
  if (!isset(data)) return [null, null];
  if (!data?.geometry?.location) return [null, null];
  return [data?.geometry?.location?.lng ?? null, data?.geometry?.location?.lat ?? null]
}

