import { Inject, Injectable } from '@angular/core';
import { Notification, User } from '@shared/models';
import { SimpleNotificationContract, SimpleNotificationService } from '@shared/services/apis';
import { ICwtAuthListener } from '@cawita/core-front/auth';
import { Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AdminAuthService implements ICwtAuthListener {
    private _sub: Subscription;

    constructor(
        @Inject(SimpleNotificationContract.get(Notification, 'admin')) private adminNotif: SimpleNotificationService<Notification>,
    ) { }

    onLogin(user: User): void {
        if(user?.accessType === 'admin')
        this._sub = this.adminNotif.init().subscribe();
    }

    onLogout(): void {
        this._sub?.unsubscribe();
        this._sub = null;
    }

}