import { QuillConfig } from "ngx-quill";
import Quill from "quill";
import { environment } from "../../environments/environment";


var AlignStyle = Quill.import('attributors/style/align');
var BackgroundStyle = Quill.import('attributors/style/background');
var ColorStyle = Quill.import('attributors/style/color');
var DirectionStyle = Quill.import('attributors/style/direction');
var FontStyle = Quill.import('attributors/style/font');
var SizeStyle = Quill.import('attributors/style/size');

Quill.register(AlignStyle, true);
Quill.register(BackgroundStyle, true);
Quill.register(ColorStyle, true);
Quill.register(DirectionStyle, true);
Quill.register(FontStyle, true);
Quill.register(SizeStyle, true);

export const CwtAdminQuillConfig: QuillConfig = {
    sanitize: false,
    theme: 'snow',
    format: 'html',
    defaultEmptyValue: '',
    placeholder: '',
    modules: {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['clean'],
        ]
    }
}