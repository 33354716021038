import { Directive, Input, OnChanges, SimpleChanges, ViewContainerRef } from '@angular/core';
import { hasSomeChanges } from '@cawita/core-front';
import { CwtMediaAddonDirective } from './media-addon.directive';
import { CwtMediaState } from './media.utils';

@Directive({ selector: '[cwtMediaAddonOutlet]' })
export class CwtMediaAddonOutletDirective implements OnChanges {

    @Input('cwtMediaAddonOutlet') public addons: Iterable<CwtMediaAddonDirective>;
    @Input('cwtMediaAddonOutletState') public state: CwtMediaState;

    private _addon: CwtMediaAddonDirective;

    constructor(
        private vcRef: ViewContainerRef,
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (hasSomeChanges(changes, ['addons', 'state']), true) {
            const newAddon = this._getAddonToDisplay();
            if (newAddon !== this._addon) {
                this._addon = newAddon;
                this._refreshView();
            }
        }
    }

    private _getAddonToDisplay() {
        if (!this.addons) return null;
        if (!this.state || this.state === 'source') return null;
        for (const addon of this.addons) {
            if (addon.type === this.state) return addon;
        }
        return null;
    }

    private _refreshView() {
        this.vcRef.clear();
        if (this._addon) this.vcRef.createEmbeddedView(this._addon._ref);
    }
}