import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { Mission, MissionCustomerStatus, MissionProStatus } from '../../../models';
import { CwtDirectiveView, asArray, hasSomeChanges, isset } from '@cawita/core-front';
import { checkMissionStatus } from '../mission.utils';

@Directive({
  selector: '[cwtIfMissionState]'
})
export class IfMissionStateDirective implements OnChanges {
  private _view = new CwtDirectiveView(this.vcRef, this.tRef, {});

  @Input('cwtIfMissionState') mission: Mission;
  @Input('cwtIfMissionStateCustomerStatus') customerStatus: MissionCustomerStatus | MissionCustomerStatus[];
  @Input('cwtIfMissionStateProStatus') proStatus: MissionProStatus | MissionProStatus[];

  constructor(
    private vcRef: ViewContainerRef,
    private tRef: TemplateRef<any>
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasSomeChanges(changes, ['mission', 'customerStatus', 'proStatus'], true)) this._update();
  }

  private _update() {
    this._view.ensureState(checkMissionStatus(this.mission, {
      customerStatus: this.customerStatus,
      proStatus: this.proStatus
    }));
  }
}
