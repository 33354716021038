import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp, CwtSchema } from "@cawita/core-front/api";
import { Media } from "./media.model";
import { Pro } from "./pro.model";

@CwtModel({ name: 'showroom' })
@CwtCrudContract({ path: '/showroom' })
@CwtCrudContract({ path: 'mine/showroom', pathPrefix: 'as-pro' })
export class Showroom extends CwtApiEntity {
    @CwtProp({ ref: Pro }) pro: Pro;
    @CwtProp({ ref: Media }) medias: Media[];
    @CwtProp() description: string;
    @CwtProp() date: number;

}