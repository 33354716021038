import { CwtDictionary } from "@cawita/core-front";
import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { Job } from "./job.model";

@CwtModel({ name: 'skill' })
@CwtCrudContract({ path: 'skill', pathPrefix: 'as-customer' })
@CwtCrudContract({
    path: '/skill',
    searchMapper: (s) => ({
        or: [
            { 'name': { regexp: s } },
        ]
    })
})
export class Skill extends CwtApiEntity {
    @CwtProp() name: CwtDictionary;
    @CwtProp({ ref: Job }) job: Job;
    @CwtProp() isActive: boolean;
}
