import { CwtCrudContract, CwtModel, CwtProp, CwtSchema, CwtSubCrudContract, CwtVirtual } from "@cawita/core-front/api";
import { BaseRating, BaseRatingMeta, sumRate } from "./rating.model";
import { Customer } from "./customer.model";
import { Pro } from "./pro.model";

export enum CustomerRateProps {
    punctuality = 'punctuality',
    accuracy = 'accuracy',
    communication = 'communication'
}

@CwtModel({ name: 'customer-rate' })
@CwtCrudContract({ path: 'customer-rate' })
@CwtCrudContract({ path: '/mine/customer-rate', pathPrefix: 'as-customer' })
@CwtSubCrudContract({ parent: Customer, parentPath: '/mine/customer', subPath: 'customer-rate', pathPrefix: 'as-pro' })
export class CustomerRate extends BaseRating {
    @CwtProp() punctuality: number;
    @CwtProp() accuracy: number;
    @CwtProp() communication: number;
    @CwtVirtual({ get: (model) => sumRate(model?.punctuality, model?.accuracy, model?.communication) }) rate: number;
}