import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CwtDirectiveView } from '@cawita/core-front';
import { Subscription } from 'rxjs';

export type LetResolvedContext<D> = {
  $implicit: D;
  cwtLetResolved: D;
  update: (value: D) => void
}

@Directive({
  selector: '[cwtLetResolved]'
})
export class LetResolvedDirective<D> implements OnInit, OnDestroy {

  private _ctx: LetResolvedContext<D> = { $implicit: null, cwtLetResolved: null, update: null };
  private _view = new CwtDirectiveView(this.vcRef, this.tRef, this._ctx);
  private _sub: Subscription;

  @Input('cwtLetResolved') key: string;

  constructor(
    private vcRef: ViewContainerRef,
    private tRef: TemplateRef<LetResolvedContext<D>>,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this._sub = this.route.data.subscribe(data => {
      this._ctx.$implicit = this._ctx.cwtLetResolved = data[this.key];
      this._ctx.update = this._update;
      this._view.ensureState(true);
    });
  }

  ngOnDestroy(): void {
    this._sub?.unsubscribe();
  }


  private _update = (data: D) => {
    this._ctx.$implicit = this._ctx.cwtLetResolved = data;
  }

}
