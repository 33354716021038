import { CwtDictionaryObject } from "@cawita/core-front";
import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { Customer } from "./customer.model";
import { Pro } from "./pro.model";
import { SimpleNotificationContract } from "../services/apis/contracts/simple-notification-contract";
import { Intervention } from "./intervention.model";
import { Mission } from "./mission.model";

@CwtModel({ name: 'notification' })

@SimpleNotificationContract({ notifGroup: 'admin', notifCheckPath: '/notification-check', notifPath: '/notification' })
@SimpleNotificationContract({ notifGroup: 'pro', notifCheckPath: 'as-pro/mine/notification-check', notifPath: 'as-pro/mine/notification' })
@SimpleNotificationContract({ notifGroup: 'customer', notifCheckPath: 'as-customer/mine/notification-check', notifPath: 'as-customer/mine/notification' })
export class Notification extends CwtApiEntity {
    @CwtProp() topics?: string[];
    @CwtProp() notifGroups?: string[];
    @CwtProp({ ref: Customer }) customer: Customer;
    @CwtProp({ ref: Pro }) pro: Pro;

    @CwtProp() title?: CwtDictionaryObject;
    @CwtProp() content?: CwtDictionaryObject;
    @CwtProp() notifType?: string;
    @CwtProp() linkId?: string[];
    @CwtProp() creationDate?: Date;

    @CwtProp({ ref: Customer }) linkedCustomer: Customer;
    @CwtProp({ ref: Pro }) linkedPro: Pro;
    @CwtProp({ ref: Intervention }) intervention: Intervention;
    @CwtProp({ ref: Mission }) mission: Mission;
}