import { CwtApiSchema, CwtProp, CwtSchema } from "@cawita/core-front/api";
import { InterventionFlow } from "../intervention.model";
import { Category } from "../category.model";

@CwtSchema()
export class PerCategoryStat extends CwtApiSchema {
    @CwtProp({ ref: Category }) category: Category;
    @CwtProp() count: number;
}

@CwtSchema()
export class ProStat extends CwtApiSchema {
    @CwtProp() count: number;
    @CwtProp({ ref: PerCategoryStat }) perCategory: PerCategoryStat[];
}