
import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { Mission } from "./mission.model";
import { Pro } from "./pro.model";

@CwtModel({ name: 'slot' })
@CwtCrudContract({ path: 'slot' })
@CwtCrudContract({ path: 'slot', pathPrefix: 'as-pro' })
@CwtCrudContract({ path: 'slot', pathPrefix: 'as-customer' })
export class Slot extends CwtApiEntity {
    @CwtProp({ ref: 'mission' }) mission: Mission;
    @CwtProp({ ref: Date }) date: Date;
    @CwtProp({ ref: 'pro' }) pro: Pro[];
}
