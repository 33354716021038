
import { Injectable } from '@angular/core';
import { CwtApiService } from '@cawita/core-front/api';
import { map } from 'rxjs';
import { BestProConfig, Pro } from '../../models';

@Injectable({ providedIn: 'root' })
export class AsProProApi {
    constructor(private api: CwtApiService) { }

    deleteCompanyMedia(fileId: string) {
        return this.api.delete(`as-pro/pro/mine/company-media/${fileId}`).pipe(
            map(res => new Pro(res))
        );
    }

    getProConfig() {
        return this.api.get(`as-pro/mine/pro-config/best`).pipe(
            map(res => new BestProConfig(res))
        )
    }

}

