import { ChangeDetectorRef, Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { CwtDirectiveView, isset } from '@cawita/core-front';
import { CwtAuthStore } from '@cawita/core-front/auth';
import { Subscription } from 'rxjs';
@Directive({ selector: '[cwtIfLogged]' })
export class IfLoggedDirective implements OnDestroy {
    private _sub: Subscription;
    private _view = new CwtDirectiveView(this.vcRef, this.tRef, {});

    constructor(
        private tRef: TemplateRef<any>,
        private vcRef: ViewContainerRef,
        private auth: CwtAuthStore,
        private cdRef: ChangeDetectorRef
    ) {

        this._sub = this.auth.stableState$.subscribe({
            next: (state) => {
                this._view.ensureState(isset(state.jwtToken));
                this.cdRef.markForCheck();
            }
        });
    }

    ngOnDestroy(): void {
        this._sub?.unsubscribe();
    }
}