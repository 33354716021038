import { Inject, Injectable } from '@angular/core';
import { formatURL } from '@cawita/core-front';
import { CwtApiPageResponse, CwtApiService, CwtCrudContract, CwtCrudService, CwtRequestConfig, mapArrayToPaginated, mapPaginatedToArray } from '@cawita/core-front/api';
import { CustomerRate } from '@shared/models';
import { map, Observable, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RatingApi {
    private basePath = 'as-pro/';
    constructor(
        private api: CwtApiService
    ) { }

    public getCustomerRate(proId: string, customerId: string, filters, config?: CwtRequestConfig) {
        return this.api.get(`${this.basePath}/${proId}/customer/${customerId}/customer-rate`, filters, config).pipe(
            mapArrayToPaginated(),
            tap(res => res.data = res.data.map(customerCustomerRate => new CustomerRate(customerCustomerRate))))
    }

}