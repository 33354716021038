// type
// for
// name
// useCases
// subject
// content
// isActive
// attachments

import { CwtDictionary, CwtDictionaryObject } from "@cawita/core-front";
import { CwtApiEntity, CwtCrudContract, CwtCrudService, CwtModel, CwtProp } from "@cawita/core-front/api";

export enum TemplateType {
    Mail = 'mail',
    Notif = 'notif',
}

export enum TemplateFor {
    App = 'app',
    System = 'system'
}

export enum TemplateSystemUseCase {
    ForgottenPassword = 'forgottenPassword',
    NewPasswordEmail = 'newPasswordEmail',
    ConfirmationRegisterMail = 'confirmationRegisterMail'
}

export enum TemplateAppUseCase {
    Generic = 'generic'
}

export type TemplateUseCase = TemplateAppUseCase | TemplateSystemUseCase;

export enum TemplateAttachement {
    Mandatory = 'mandatory',
    Accepted = 'accepted',
    None = 'none'
}

@CwtModel({ name: 'template' })
@CwtCrudContract({
    path: 'template',
    searchMapper: (search) => ({
        or: [
            { 'name.fr': { regexp: search } },
            { 'name.en': { regexp: search } },
            { 'content.fr': { regexp: search } },
            { 'content.en': { regexp: search } },
        ]
    })
})
export class Template extends CwtApiEntity {
    @CwtProp() type: TemplateType;
    @CwtProp() for: TemplateFor;
    @CwtProp() name: string;
    @CwtProp() useCases: TemplateUseCase[];
    @CwtProp() subject: CwtDictionaryObject;
    @CwtProp() content: CwtDictionaryObject;
    @CwtProp() isActive: boolean;
    @CwtProp() attachments: TemplateAttachement;
}