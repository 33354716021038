import { Directive, Input, TemplateRef } from '@angular/core';
import { CwtMediaState } from './media.utils';

@Directive({
    selector: '[cwtMediaAddon],[cwtImageAddon],[cwtVideoAddon]',
    host: {
        class: 'cwt-media-addon-host'
    }
})
export class CwtMediaAddonDirective {
    @Input('cwtMediaAddon') public mType: Exclude<CwtMediaState, 'source'>;
    @Input('cwtImageAddon') public iType: Exclude<CwtMediaState, 'source'>;
    @Input('cwtVideoAddon') public vType: Exclude<CwtMediaState, 'source'>;

    public get type() { return this.mType ?? this.iType ?? this.vType; }

    constructor(public readonly _ref: TemplateRef<any>) { }
}