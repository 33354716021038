import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})

export class OrderByPipe implements PipeTransform {
  transform(value: any) {
    if(value) return value.sort((a, b) => (a.name.fr > b.name.fr ? 1 : -1)
    );

  }
}