import { CwtSchema, CwtApiSchema, CwtProp } from "@cawita/core-front/api";

export enum MarketProvider {
    Google = 'google',
    Apple = 'apple',
    Stripe = 'stripe'
}

@CwtSchema()
export class BaseMarketProduct extends CwtApiSchema {
    @CwtProp() amount: number;
    @CwtProp() currency: string;
    @CwtProp() currencies: Record<string, number>;
}