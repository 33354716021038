import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'cwt-loading-addon',
  templateUrl: './loading-addon.component.html',
  styleUrls: ['./loading-addon.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingAddonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
