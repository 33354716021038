import { Directive, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { CwtDirectiveView, hasSomeChanges, isset, sameId } from '@cawita/core-front';
import { User } from '@shared/models';
import { Subscription } from 'rxjs';
import { LoggedUserStore } from './user.store';

@Directive({
  selector: '[cwtIsNotLoggedUser]'
})
export class IsNotLoggedUserDirective implements OnInit, OnChanges, OnDestroy {

  private _sub: Subscription;
  private _view = new CwtDirectiveView(this.vcRef, this.tRef);

  private _loggedUser: User;
  @Input('cwtIsNotLoggedUser') user: User;

  constructor(
    private tRef: TemplateRef<void>,
    private vcRef: ViewContainerRef,
    private userStore: LoggedUserStore
  ) { }

  ngOnDestroy(): void {
    this._sub?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasSomeChanges(changes, ['user'])) this._updateView(this._loggedUser, this.user);
  }

  ngOnInit(): void {
    this._sub = this.userStore.stableState$.subscribe({
      next: (state) => {
        this._loggedUser = state.user;
        this._updateView(this._loggedUser, this.user);
      }
    });
  }

  private _updateView(loggedUser: User, otherUser: User) {
    this._view.ensureState(!sameId(loggedUser, otherUser));
  }

}