import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp, CwtSchema } from "@cawita/core-front/api";
import { Role } from "./role.model";
import { forwardRef } from "@angular/core";

@CwtModel({ name: 'user' })
@CwtCrudContract({
    path: '/user',
    searchMapper: (s) => ({
        or: [
            { firstName: { regexp: s } },
            { lastName: { regexp: s } },
            { email: { regexp: s } },
        ]
    }),
})
export class User extends CwtApiEntity {
    @CwtProp() firstName?: string;
    @CwtProp() lastName?: string;
    @CwtProp() email?: string;
    @CwtProp() emailValid?: boolean;
    @CwtProp() phoneValid?: boolean;
    @CwtProp() accessType?: string;
    @CwtProp({ ref: Role }) role?: Role;
    @CwtProp() picture: string;
    @CwtProp() phone: string;
    @CwtProp() lang: string;
    @CwtProp({ ref: forwardRef(() => NotificationConfig) }) notification: NotificationConfig
}

@CwtSchema()
export class NotificationConfig {
    @CwtProp({ default: true }) enablePush: boolean;
    @CwtProp({ default: true }) enableSummary: boolean;
    @CwtProp({ default: true }) acceptProUpdateNotif: boolean;
}