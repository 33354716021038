import { CwtModel, CwtProp, CwtSchema } from "@cawita/core-front/api";
import { BaseMarketProduct } from "./market.model";
import type { Pro } from "../pro.model";
import type { Product } from "./product.model";


@CwtModel({ name: 'stripe-product' })
export class StripeProduct extends BaseMarketProduct {
    @CwtProp() name: string;
    @CwtProp() productId: string;
    @CwtProp() description: string;
    @CwtProp() priceId: string;
    @CwtProp() active: string;
}

@CwtSchema()
export class StripeSubscriptionData {
    @CwtProp({ ref: Date }) canceled_at: Date;
    @CwtProp({ ref: Date }) ended_at: Date;
    @CwtProp({ ref: Date }) created: Date;
    @CwtProp({ ref: Date }) start_date: Date;
    @CwtProp({ ref: Date }) current_period_start: Date;
    @CwtProp({ ref: Date }) current_period_end: Date;
}

@CwtModel({ name: 'stripe-subscription ' })
export class StripeSubscription {
    @CwtProp() status: string;
    @CwtProp() stripeId: string;
    @CwtProp() priceId: string;
    @CwtProp() isRenew: boolean;
    @CwtProp({ ref: 'pro' }) pro: Pro;
    @CwtProp({ ref: 'product' }) product: Product;
    @CwtProp({ ref: 'stripe-product' }) stripeProduct: StripeProduct;
    @CwtProp({ ref: StripeSubscriptionData }) data: StripeSubscriptionData;
}