import { Pipe, PipeTransform } from '@angular/core';
import { CwtValueAccessor, findValueInObject } from '@cawita/core-front';

@Pipe({
    name: 'getCoordinatesCenter'
})

export class GetCoordinatesCenterPipe<D> implements PipeTransform {
    transform(values: D[], path: CwtValueAccessor<D, [number, number]>): [number, number] {
        const coordinates = values.map(v => findValueInObject(v, path));
        const center = getLatLngCenter(coordinates);
        return center;
    }
}

function rad2degr(rad: number) { return rad * 180 / Math.PI; }
function degr2rad(degr: number) { return degr * Math.PI / 180; }

function getLatLngCenter(coordinates: ([number, number])[]): [number, number] {
    if (coordinates.length === 1) return coordinates[0];

    let sumX = 0;
    let sumY = 0;
    let sumZ = 0;

    for (var i = 0; i < coordinates.length; i++) {
        const lng = degr2rad(coordinates[i][0]);
        const lat = degr2rad(coordinates[i][1]);
        // sum of cartesian coordinates
        sumX += Math.cos(lat) * Math.cos(lng);
        sumY += Math.cos(lat) * Math.sin(lng);
        sumZ += Math.sin(lat);
    }

    const avgX = sumX / coordinates.length;
    const avgY = sumY / coordinates.length;
    const avgZ = sumZ / coordinates.length;

    // convert average x, y, z coordinate to latitude and longtitude
    const lng = Math.atan2(avgY, avgX);
    const hyp = Math.sqrt(avgX * avgX + avgY * avgY);
    const lat = Math.atan2(avgZ, hyp);

    return ([rad2degr(lng), rad2degr(lat)]);
}
