import { Directive, EventEmitter, HostListener, Inject, Input, Optional, Output } from '@angular/core';
import { ICwtMediaViewerAdapter, MEDIA_VIEWER_ADAPTER } from './media-viewer-adapter';
import { CwtMediaType } from './media.utils';

@Directive({
  selector: '[cwtOpenMediaViewer]',
  host: {
    '[class.clickable]': 'enabled && !disabled'
  }
})
export class OpenMediaViewerDirective {

  public enabled = !!this.mediaViewer;
  @Input('cwtOpenMediaViewer') type: CwtMediaType;
  @Input() src: string | File;
  @Input() name: string;
  @Input() delete = false;
  @Input() disabled = false;
  @Output() onDelete = new EventEmitter<void>();

  constructor(
    @Optional() @Inject(MEDIA_VIEWER_ADAPTER) private mediaViewer: ICwtMediaViewerAdapter
  ) { }

  @HostListener('click', ['$event'])
  public onClick($event: Event) {
    if (!this.enabled) return;
    if (this.disabled) return;

    $event?.stopPropagation();
    $event?.preventDefault();
    this.mediaViewer.viewMedia({
      name: this.name,
      src: this.src,
      type: this.type,
      onDelete: this.delete ? () => this.onDelete.emit() : null
    });
  }
}
