import { NgModule } from '@angular/core';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { AppCommonModule } from './common/common.module';

@NgModule({
    imports: [],
    exports: [
        AppCommonModule,
        NzLayoutModule,
    ],
    declarations: [],
    providers: [],
})
export class AppLayoutModule { }
