
export type CwtMediaType = 'video' | 'image';
export type CwtMediaFitting = 'cover' | 'contain' | 'fill' | 'scale-down' | 'none';
export type CwtMediaState = 'placeholder' | 'source' | 'loading' | 'error';
export type CwtMediaShape = 'round' | 'circle' | 'default' | 'corner';
export type CwtMediaBorder = 'default' | 'outline' | 'dashed';

export const MediaBorderRadius: Record<CwtMediaShape, string> = {
    default: 'var(--cwt-media-border-radius-default, 0px)',
    round: 'var(--cwt-media-border-radius-round, 8px)',
    circle: 'var(--cwt-media-border-radius-circle, 100rem)',
    corner: 'var(--cwt-media-border-radius-corner, 8px 0px 8px 0px)'
}

export const MediaBorderStyle: Record<CwtMediaBorder, string> = {
    default: 'var(--cwt-media-border-default, inherit)',
    outline: 'var(--cwt-media-border-outline, 1px solid #d9d9d9)',
    dashed: 'var(--cwt-media-border-dashed, 1px dashed #d9d9d9)',
}