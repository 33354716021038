import { Injectable } from '@angular/core';
import { isset } from '@cawita/core-front';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class TranslationUtilsService {
    constructor(private translate: TranslateService) { }

    public translateEnumValue(prefix: string, value: string | number): string {
        if (!isset(value)) return '';
        const translated: string = this.translate.instant(`${prefix}.${value}`);
        if (translated.startsWith(`${prefix}.`)) return value?.toString();
        return translated;
    }
}