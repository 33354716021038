import { ContentChildren, Directive, HostBinding, Input, QueryList } from '@angular/core';
import { CwtMediaAddonDirective } from './media-addon.directive';
import { CwtMediaFitting, CwtMediaShape, CwtMediaState, CwtMediaBorder, MediaBorderRadius, MediaBorderStyle } from './media.utils';

@Directive()
export class AbstractMediaDirective {
    @Input() src: string | File = null;
    @Input() shape: CwtMediaShape = 'default';
    @Input() border: CwtMediaBorder = 'default';

    @HostBinding('style.--cwt-media-width') @Input() width: string;
    @HostBinding('style.--cwt-media-height') @Input() height: string;
    @HostBinding('style.--cwt-media-ratio') @Input() ratio: string;
    @HostBinding('style.--cwt-media-object-fit') @Input() fitting: CwtMediaFitting = 'cover';
    @HostBinding('style.--cwt-media-border-radius') public get borderRadiusStyle(): string { return MediaBorderRadius[this.shape]; }
    @HostBinding('style.--cwt-media-border') public get borderStyle(): string { return MediaBorderStyle[this.border]; }
    @ContentChildren(CwtMediaAddonDirective) public addons: QueryList<CwtMediaAddonDirective>;

    public state: CwtMediaState = 'placeholder';

    constructor() { }
}