import { CwtApiSchema, CwtProp, CwtSchema } from "@cawita/core-front/api";
import { SubscriptionSubType } from "../market"

@CwtSchema()
export class PerSubTypeStat extends CwtApiSchema {
    @CwtProp() subType: SubscriptionSubType;
    @CwtProp() count: number;
}
@CwtSchema()
export class SubscriptionStat extends CwtApiSchema {
    @CwtProp() activeCount: number;
    @CwtProp() inactiveCount: number;
    @CwtProp({ ref: PerSubTypeStat }) perSubType: PerSubTypeStat[];
}