import { CwtApiSchema, CwtProp, CwtSchema } from '@cawita/core-front/api';
import { CustomerStat } from './customer-stat';
import { InterventionStat } from './intervention-stat';
import { ProStat } from './pro-stat';
import { SubscriptionStat } from './subscription-stat';

@CwtSchema()
export class DashboardStat extends CwtApiSchema {
    @CwtProp({ ref: SubscriptionStat }) subscription: SubscriptionStat;
    @CwtProp({ ref: CustomerStat }) customer: CustomerStat;
    @CwtProp({ ref: InterventionStat }) intervention: InterventionStat;
    @CwtProp({ ref: ProStat }) pro: ProStat;
}

// @index(['./!(*.spec).ts', './*/index.ts'], f => `export * from '${f.path.replace(/\/index$/, '')}';`)
export * from './customer-stat';
export * from './intervention-stat';
export * from './pro-stat';
export * from './subscription-stat';
// @endindex