import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppCommonModule } from '../common/common.module';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCardModule } from 'ng-zorro-antd/card';
import { CwtFormModule } from '@cawita/core-front/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { FormCardComponent } from './form-card/form-card.component';
import { CommonModule } from '@angular/common';
import { NzOutletModule } from 'ng-zorro-antd/core/outlet';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';

@NgModule({
  imports: [
    CommonModule,
    CwtFormModule,
    NzCardModule,
    NzOutletModule,
  ],
  exports: [
    AppCommonModule,
    ReactiveFormsModule,
    CwtFormModule,
    FormsModule,
    NzDividerModule,
    NzGridModule,
    NzCardModule,
    NzInputNumberModule,
    NzInputModule,
    NzDatePickerModule,
    NzSelectModule,
    NzCheckboxModule,
    NzSwitchModule,
    NzSliderModule,
    NzFormModule,
    FormCardComponent
  ],
  declarations: [
    FormCardComponent
  ],
  providers: [],
})
export class AppFormModule { }
