import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";

@CwtModel({ name: 'role' })
@CwtCrudContract({
    path: '/role',
    searchMapper: (s) => ({ name: { regexp: s } }),
})
export class Role extends CwtApiEntity {
    @CwtProp() name?: string;
    @CwtProp() accessType?: string;
    @CwtProp() permissions: string[];
}