import { CwtDictionaryObject } from "@cawita/core-front";
import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { ProConfigFeatures } from "../pro-config.model";
import { UploadFile } from "../upload-file.model";
import { AppleProduct } from "./apple.model";
import { BaseMarketProduct } from "./market.model";

import type { StripeProduct } from "./stripe.model";
import { GoogleProduct } from "./google.model";

export type StoreProduct = StripeProduct | AppleProduct | GoogleProduct;

export enum ProductType {
    ProSubscription = 'proSubscription'
}

@CwtModel({ name: 'product' })
@CwtCrudContract({ path: '/product', searchMapper: (s) => ({ 'name.fr': { regexp: s } }), })
@CwtCrudContract({ path: '/product', pathPrefix: 'as-pro', })
export class Product extends CwtApiEntity {
    @CwtProp() name?: CwtDictionaryObject;
    @CwtProp({ ref: UploadFile }) image: UploadFile;
    @CwtProp() type: ProductType;
    @CwtProp({ ref: 'stripe-product' }) stripeProduct: StripeProduct;
    @CwtProp({ ref: 'apple-product' }) appleProduct: AppleProduct;
    @CwtProp({ ref: 'google-product' }) googleProduct: GoogleProduct;
    @CwtProp() isActive: boolean;
    @CwtProp({ ref: ProConfigFeatures }) featureFlags: ProConfigFeatures;
}