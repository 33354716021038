import { Inject, Injectable } from '@angular/core';
import { getId, sameId } from '@cawita/core-front';
import { CwtApiService, CwtCrudContract, CwtCrudService, CwtRequestConfig } from '@cawita/core-front/api';
import { Observable, catchError, map, of, switchMap, tap } from 'rxjs';
import { Intervention, InterventionFlow, Mission } from '../../models';
import { getMainMissionForCustomer } from '@shared/modules/shared-intervention';

@Injectable({ providedIn: 'root' })
export class AsCustomerInterventionApi {
    constructor(
        @Inject(CwtCrudContract.get(Mission, 'as-customer')) private customerMissionCrud: CwtCrudService<Mission>,
        @Inject(CwtCrudContract.get(Intervention, 'as-customer')) private customerInterventionCrud: CwtCrudService<Intervention>,
        private api: CwtApiService
    ) { }

    public countPro(intervention: any) {
        return this.api.post<number>(`/as-customer/mine/intervention/count-matching-pro`, intervention);
    }

    public getWithMissions(filter: any, config?: CwtRequestConfig) {
        return this.customerInterventionCrud.getPage(filter, config).pipe(
            switchMap(res => this.customerMissionCrud.getArray({ intervention: { in: res.data.map(getId) } }).pipe(
                catchError(() => of([])),
                tap(missions => res.data.forEach(i => {
                    i.mission = getMainMissionForCustomer(missions.filter(m => sameId(m.intervention, i)), i.flow);
                    i.missions = missions.filter(m => sameId(m.intervention, i));
                    // i.meta = computeInterventionMeta(i, missions);
                })),
                map(() => res)
            ))
        )
    }

    public createForFlow(flow: InterventionFlow, payload, config?: CwtRequestConfig) {
        return this.api.post<any>(`/as-customer/mine/intervention/${flow}`, payload, config).pipe(
            map(intervention => new Intervention(intervention))
        );
    }

    public deleteForFlow(flow: InterventionFlow, id: string, config?: CwtRequestConfig) {
        return this.api.delete<any>(`/as-customer/mine/intervention/${id}/flow/${flow}`, {}, config).pipe(
            map(intervention => new Intervention(intervention))
        );
    }

    public updateForFlow(flow: InterventionFlow, id: string, payload: any, config?: CwtRequestConfig) {
        return this.api.patch(`/as-customer/mine/intervention/${id}/flow/${flow}`, payload, config).pipe(
            map(intervention => new Intervention(intervention))
        );
    }

    public getMissionForIntervention(interventionId: string): Observable<Mission[]> {
        return this.api.get<any[]>(`as-customer/mine/intervention/${interventionId}/mission`).pipe(
            map(res => res.map(r => new Mission(r))),
        );
    }
}