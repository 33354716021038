import { ChangeDetectionStrategy, Component, EventEmitter, Inject, InjectionToken, OnInit, Output, ViewChild } from '@angular/core';
import { InvisibleReCaptchaComponent } from 'ngx-captcha';

export const RECAPTCHA_SITE_KEY = new InjectionToken<string>('cwt-recaptcha-site-key');

@Component({
  selector: 'cwt-recaptcha',
  templateUrl: './recaptcha.component.html',
  styleUrls: ['./recaptcha.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecaptchaComponent implements OnInit {
  public _response: string;

  @ViewChild(InvisibleReCaptchaComponent, { static: true }) private _recaptcha!: InvisibleReCaptchaComponent;

  @Output() success = new EventEmitter<string>();

  constructor(
    @Inject(RECAPTCHA_SITE_KEY) public readonly siteKey: string
  ) { }

  ngOnInit(): void {
  }

  public execute() { this._recaptcha.execute(); }

  public onSuccess(res: string) {
    this.success.emit(res);
    this._response = null;
    this._recaptcha.resetCaptcha();
  }

}
