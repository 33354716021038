import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CwtCommonModule } from '@cawita/core-front';
import { CwtAssetModule } from '@cawita/core-front/asset';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { AvatarAddonComponent } from './addons/avatar-addon/avatar-addon.component';
import { IconAddonComponent } from './addons/icon-addon/icon-addon.component';
import { CwtImageComponent } from './image/image.component';
import { CwtMediaAddonOutletDirective } from './media-addon-outlet.directive';
import { CwtMediaAddonDirective } from './media-addon.directive';
import { TextColorPipe } from './text-color.pipe';
import { CwtVideoComponent } from './video/video.component';
import { OpenMediaViewerDirective } from './open-media-viewer.directive';
import { LoadingAddonComponent } from './addons/loading-addon/loading-addon.component';

const ADDONS = [
  AvatarAddonComponent,
  IconAddonComponent,
  LoadingAddonComponent,
]


@NgModule({
  declarations: [
    CwtMediaAddonDirective,
    CwtMediaAddonOutletDirective,
    CwtImageComponent,
    CwtVideoComponent,
    TextColorPipe,
    OpenMediaViewerDirective,
    ...ADDONS,
  ],
  imports: [
    CommonModule,
    NzIconModule,
    CwtAssetModule,
    CwtCommonModule,
  ],
  exports: [
    CwtAssetModule,
    CwtCommonModule,
    CwtImageComponent,
    CwtMediaAddonDirective,
    CwtVideoComponent,
    OpenMediaViewerDirective,
    ...ADDONS,
  ]
})
export class CwtMediaModule { }
